import { StepFunction } from "./types";

// security
export const step5: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  let P_total = 0,
    P_FF1 = 0,
    P_FF2 = 0,
    P_FC1 = 0,
    P_FF3_FF4 = 0,
    P_FF5 = 0,
    P_FC2 = 0;
  // probability that seat is infected
  P_FF1 =
    ((params.gate.seats *
      params.gate.blocked_seats *
      hidden_contagious *
      (((params.hand_infection.not_disinfecting - hidden_contagious) **
        (params.gate.flights_used - 1) -
        1) /
        (params.hand_infection.not_disinfecting - hidden_contagious - 1))) /
      params.num_pax) *
    params.hand_infection.poor_surface_cleaning;

  // probability to touch face
  P_FF2 =
    (1 - collective_immunity) *
    params.mask_proba.ratio_touch_mask *
    Math.min(1, params.time.time_gate * params.mask_proba.rate_min_touch_face);

  // probability of FC1
  P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

  // probability that there is a contagious person nearby
  P_FF3_FF4 = hidden_contagious;

  // probability that not protected against contamination
  P_FF5 =
    (1 - collective_immunity) *
    params.gate.no_social_distance *
    params.gate.ppl_closeby *
    params.mask_proba.eff_reduction1 *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) ** params.time.time_gate);

  // probability of FX2
  P_FC2 = P_FF3_FF4 * P_FF5;

  // calculate total risk
  P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
