import React from "react";
import uniqueId from "lodash.uniqueid";
import { useChartScales, useChartRanges } from "./Chart";

type RuleYProps = {
  y: number[];
  color?: string;
};

export const RuleY = (props: RuleYProps) => {
  const { y: yScale } = useChartScales();
  const { x: xRange } = useChartRanges();
  const { y } = props;
  return (
    <g>
      {y.map((v) => (
        <line
          key={uniqueId("line-")}
          x1={xRange[0]}
          y1={yScale(y)}
          x2={xRange[1]}
          y2={yScale(y)}
          stroke={"black"}
          strokeWidth={"2"}
        />
      ))}
    </g>
  );
};
