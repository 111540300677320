import React from "react";
import MetricCard from "./ui/MetricCard";
import { useParams, useTestScenario } from "../data/store";
import { StepInfectionProbabilities } from "../data/steps/types";
import {
  Card,
  CardHeader,
  CardContent,
  createUseStyles,
  Toggle,
} from "@airbus/components-react";
import { applyVaccineImpact } from "../data";

const useStyles = createUseStyles((theme: any) => {
  const { color, font } = theme;
  return {
    root: {
      maxWidth: "initial !important",
    },
    content: {
      display: "flex",
      marginTop: 16,
    },
    explainer: {
      color: color.text.secondary,
      fontFamily: "inherit",
      fontSize: font.size.xsmall,
      lineHeight: font.lineheight.xsmall,
    },
  };
}, "metric");

type Props = {
  hiddenContagiousRate: number;
  probabilitiesByStep: StepInfectionProbabilities[];
};

const OverallRiskAssessment = ({
  hiddenContagiousRate,
  probabilitiesByStep,
}: Props) => {
  const classes = useStyles();

  const params = useParams();
  const scenario = useTestScenario(params.test_scenario);
  const [per100000, setPer100000] = React.useState(true);

  const totals: any = probabilitiesByStep.reduce(
    (acc, { name, values }) => ({ ...acc, [name]: values.P_total }),
    {}
  );

  const sum_terminal = applyVaccineImpact(
    applyVaccineImpact(
      (totals.BTE +
        totals.checkin +
        totals.security +
        totals.immigration +
        totals.gate +
        totals.boarding +
        2 * totals.bridge +
        totals.baggage) *
        (1 - scenario.test_eff_departure / 100),
      params
    ),
    params
  );

  const sum_aircraft = applyVaccineImpact(
    applyVaccineImpact(
      (totals.acdoors +
        totals.entering +
        totals.seated +
        totals.catering +
        totals.lavatories +
        totals.disembarking) *
        (1 - scenario.test_eff_departure / 100),
      params
    ),
    params
  );

  const overall_travel_risk = sum_terminal + sum_aircraft;

  const hidden_contagious = applyVaccineImpact(
    hiddenContagiousRate * (1 - scenario.test_eff_departure / 100),
    params
  );

  const total_risk = scenario.apply_e2e_infected
    ? (hidden_contagious + overall_travel_risk) *
      (1 - scenario.test_eff_arrival / 100)
    : hidden_contagious * (1 - scenario.test_eff_arrival / 100) +
      overall_travel_risk;

  let formatted;

  if (per100000) {
    formatted = {
      sum_terminal: (sum_terminal * 100000).toFixed(1),
      sum_aircraft: (sum_aircraft * 100000).toFixed(1),
      overall_travel_risk: (overall_travel_risk * 100000).toFixed(0),
      hidden_contagious: (hidden_contagious * 100000).toFixed(0),
      total_risk: (total_risk * 100000).toFixed(0),
    };
  } else {
    formatted = {
      sum_terminal: `1/${(sum_terminal ** -1).toFixed(0)}`,
      sum_aircraft: `1/${(sum_aircraft ** -1).toFixed(0)}`,
      overall_travel_risk: `1/${(overall_travel_risk ** -1).toFixed(0)}`,
      hidden_contagious: `${(hidden_contagious * 100).toFixed(3)}%`,
      total_risk: `${(total_risk * 100).toFixed(3)}%`,
    };
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Overall Risk Assessment"
        action={
          <Toggle
            label="Per 100,000 people"
            checked={per100000}
            onChange={(_, checked) => setPer100000(checked)}
          />
        }
      />
      <CardContent>
        <p className={classes.explainer}>
          Based on departure country Covid data from Worldometer
        </p>
        <div className={classes.content}>
          <MetricCard
            title="Non-Detected Cases on Arrival"
            number={formatted.total_risk}
          />
          <MetricCard
            title="Hidden Contagious before Travel"
            number={formatted.hidden_contagious}
          />
          <MetricCard
            title="Overall Travel Risk"
            number={formatted.overall_travel_risk}
          />
          <MetricCard
            title="Risk in Terminal"
            number={formatted.sum_terminal}
          />
          <MetricCard
            title="Risk in Aircraft"
            number={formatted.sum_aircraft}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default OverallRiskAssessment;
