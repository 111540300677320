import React from "react";
import {
  Header,
  Spinner,
  Tab,
  Tabs,
  Container,
  createUseStyles,
  Toggle,
} from "@airbus/components-react";
import CommonParams from "./CommonParams";
import SingleScenarioTab from "./SingleScenarioTab";
import ExplorationTab from "./ExplorationTab";
import { useStore } from "../data/store";
import AdvancedParams from "./AdvancedParams";
import SingleScenarioModeParams from "./SingleScenarioModeParams";
import ExplorationModeParams from "./ExplorationModeParams";

const useStyles = createUseStyles((theme: any) => {
  const { elevation, color, font } = theme;
  return {
    panel: {
      boxShadow: elevation.level3,
      marginBottom: 20,
      position: "sticky",
      top: 55,
      background: color.background.primary,
      zIndex: elevation.zindex.drawer,
    },
    spinner: {
      display: "flex",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
    filters: { display: "flex", padding: "16px 24px" },
    column: { flex: "50%" },
    main: {},
    advanced: {
      marginLeft: 96,
      marginTop: 4,
    },
    disclaimer: {
      fontSize: font.size.xsmall,
      color: color.text.tertiary,
    },
  };
}, "dashboard");

const Dashboard = () => {
  const classes = useStyles();
  const loading = useStore((state) => state.loading);
  const fetchData = useStore((state) => state.fetchData);
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);
  const [selectedTab, selectTab] = React.useState(0);
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  if (loading)
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  return (
    <>
      <Header appName="E2E Risk Assessment - Air Travel during Covid19">
        <Tabs
          aria-label="Select mode"
          value={selectedTab}
          onChange={(_, value) => selectTab(value)}
        >
          <Tab>Single Scenario</Tab>
          <Tab>Exploration</Tab>
        </Tabs>
        <Toggle
          id="advanced"
          className={classes.advanced}
          label="Advanced"
          checked={showAdvanced}
          onChange={(event) => setShowAdvanced(event.target.checked)}
        />
      </Header>
      <div className={classes.panel}>
        <Container component="div" fixed>
          <CommonParams />
          {showAdvanced && <AdvancedParams />}
          {selectedTab === 0 && <SingleScenarioModeParams />}
          {selectedTab === 1 && <ExplorationModeParams />}
        </Container>
      </div>
      <div className={classes.main}>
        <Container component="main" fixed>
          {selectedTab === 0 && <SingleScenarioTab />}
          {selectedTab === 1 && <ExplorationTab />}
          <Disclaimer className={classes.disclaimer} />
        </Container>
      </div>
    </>
  );
};

export default Dashboard;

const Disclaimer = ({ className }: any) => (
  <div className={className}>
    <p>
      The E2E risk assessment tool (the “E2E Tool”) is developed by Airbus,
      using public health data and information publicly available at a given
      time as well as on various parameters, variables and calculation methods
      based on specific assumptions.
    </p>
    <p>
      References, sources and detailed methodology used by Airbus to develop the
      E2E Tool are listed and explained in the document entitled “Hidden
      infectious and transmission during the travel Probability model detailed
      description” ref PR2100534 issue 2.
    </p>
    <p>
      Any interpretation and/or use of data generated by the E2E Tool shall be
      made under the sole and entire responsibility of their author. Airbus
      shall in no event be held liable for any interpretation or any use of data
      generated by the E2E Tool.
    </p>
    <p>
      COVID-19 statistics are synchronized from&nbsp;
      <a href="https://www.worldometers.info/coronavirus/">Worldometer</a>. The
      data used are public health data gathered by Worldometer from official
      reports.
    </p>
  </div>
);
