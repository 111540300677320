import { StepFunction } from "./types";

// catering
export const step11: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  let P_total = 0,
    P_FF1 = 0,
    P_FF2 = 0,
    P_FC1 = 0,
    P_FF3_FF4 = 0,
    P_FF5 = 0,
    P_FC2 = 0;

  if (params.catering) {
    // probability that hands get infected from cabin crew / meal
    P_FF1 =
      (hidden_contagious * params.hand_infection.staff_not_disinfected +
        params.hand_infection.meal_contamination) *
      params.hand_infection.incorrect_usage;

    // probability to touch face
    P_FF2 =
      (1 - collective_immunity) *
      params.mask_proba.ratio_touch_catering *
      Math.min(
        1,
        params.time.time_eating * params.mask_proba.rate_min_touch_face
      );

    // probability of FC1
    P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

    // probability that there is a contagious person nearby
    P_FF3_FF4 = hidden_contagious;

    // probability that not protected against contamination
    // SCENARIO 1 - person closeby sneezes and has no mask
    const P_FF5_s1 =
      (((1 - collective_immunity) *
        1 *
        (1 -
          (1 - params.boarding.pax_eating_same_time) **
            params.boarding.pax_seated_closeby) *
        params.mask_proba.fail_catering) /
        params.measures.air_con_risk_red) *
      (1 -
        (1 - params.mask_proba.rate_coughing_sneezing) **
          params.time.time_eating);

    // SCENARIO 2 - person closeby sneezes and has a mask
    const P_FF5_s2 =
      (((1 - collective_immunity) *
        (1 - params.boarding.pax_eating_same_time) **
          params.boarding.pax_seated_closeby *
        params.mask_proba.fail_eat_sneeze) /
        params.measures.air_con_risk_red) *
      (1 -
        (1 - params.mask_proba.rate_coughing_sneezing) **
          params.time.time_eating);

    P_FF5 = P_FF5_s1 + P_FF5_s2;

    // probability of FC2
    P_FC2 = P_FF3_FF4 * (P_FF5_s1 + P_FF5_s2);
  }

  //if no catering
  else {
    P_FC1 = 0;
    P_FC2 = 0;
  }

  // calculate total risk
  P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
