import { StepFunction } from "./types";

// immigration
export const step4: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  let P_total = 0,
    P_FF1 = 0,
    P_FF2 = 0,
    P_FC1 = 0,
    P_FF3_FF4 = 0,
    P_FF5 = 0,
    P_FC2 = 0;

  //COUNTER
  if (params.immigration === "counter") {
    //probability that hands get infected at immigration counter
    //SCENARIO 1 - through staff
    const P_FF1_s1 =
      hidden_contagious *
      params.hand_infection.not_disinfecting *
      params.hand_infection.staff_not_disinfected;
    //SCENARIO 2 - through touching counter
    const P_FF1_s2 =
      hidden_contagious *
      params.hand_infection.not_disinfecting *
      params.hand_infection.counter_touch *
      params.hand_infection.poor_hand_cleaning *
      params.hand_infection.same_touch_counter;

    P_FF1 = P_FF1_s1 + P_FF1_s2;

    // probability to touch face
    P_FF2 =
      (1 - collective_immunity) *
      params.mask_proba.ratio_touch_mask *
      Math.min(
        1,
        params.time.time_checkin_counter * params.mask_proba.rate_min_touch_face
      );

    //probability of FC1
    P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

    // probability that there is a contagious person nearby
    P_FF3_FF4 = hidden_contagious;

    // probability that not protected against contamination
    // SCENARIO 1 - person closeby sneezes and does not respect social distance
    const P_FF5_s1 =
      (1 - collective_immunity) *
      ground_disrespect *
      params.mask_proba.eff_reduction1 *
      (1 -
        (1 - params.mask_proba.rate_coughing_sneezing) **
          params.time.time_immigration_queue);

    // SCENARIO 2 - infection from the staff
    const P_FF5_s2 =
      (1 - collective_immunity) *
      params.counters.glass_defect *
      params.mask_proba.eff_reduction1 *
      (1 -
        (1 - params.mask_proba.rate_coughing_sneezing) **
          params.time.timeat_immigration);

    P_FF5 = P_FF5_s1 + P_FF5_s2;

    P_FC2 = P_FF3_FF4 * P_FF5;
  }

  //-----------------------------------------------------
  //SELF-SERVICE KIOSK / ePassport
  else if (params.immigration === "kiosk") {
    //probability that hands get infected at immigration kiosk
    P_FF1 =
      params.hand_infection.not_disinfecting *
      params.hand_infection.poor_surface_cleaning *
      hidden_contagious;

    // probability to touch face
    P_FF2 =
      (1 - collective_immunity) *
      params.mask_proba.ratio_touch_mask *
      Math.min(
        1,
        params.time.time_epassport * params.mask_proba.rate_min_touch_face
      );

    //probability of FC1
    P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

    // probability that there is a contagious person nearby
    P_FF3_FF4 = hidden_contagious;

    // probability that not protected against contamination
    P_FF5 =
      (1 - collective_immunity) *
      ground_disrespect *
      params.mask_proba.eff_reduction1 *
      (1 -
        (1 - params.mask_proba.rate_coughing_sneezing) **
          params.time.time_epassport_queue);

    P_FC2 = P_FF3_FF4 * P_FF5;
  }

  //------------------------------------------------------
  //no immigration ie local flight
  else {
    P_FC1 = 0;
    P_FC2 = 0;
  }

  // calculate total risk
  P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
