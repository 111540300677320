import React, { ReactElement } from "react";
import uniqueId from "lodash.uniqueid";
import { useChartScales } from "./Chart";
import { getValueAccessors, MarkProps } from "./Mark";

export type BarProps = MarkProps & {
  width?: number;
  paddingInner?: number;
  paddingOuter?: number;
  color?: string;
  offset?: number;
  tooltip?: ReactElement;
};

const TOOLTIP_HEIGHT = 32;
const TOOLTIP_MARGIN_FROM_CURSOR = 8;

const getEventHandlers = (tooltipRef: any, datum: any) => ({
  onMouseOver: () => {
    tooltipRef.current.firstChild.textContent = datum;
    tooltipRef.current.style.opacity = 1;
  },
  onMouseLeave: () => {
    tooltipRef.current.style.opacity = 0;
  },
  onMouseMove: (event: any) => {
    tooltipRef.current.style.top = `${
      event.pageY - TOOLTIP_HEIGHT - TOOLTIP_MARGIN_FROM_CURSOR
    }px`;
    tooltipRef.current.style.left = `${
      event.pageX - TOOLTIP_MARGIN_FROM_CURSOR
    }px`;
  },
});

export const Bar = (props: BarProps) => {
  const tooltipRef = React.useRef(null);
  const { x: xScale, y: yScale } = useChartScales();
  const accessors = getValueAccessors(props);
  const { data, offset, width, tooltip, color = "#255FCC" } = props;
  return (
    <>
      <g transform={offset ? `translate(${offset}, 0)` : undefined}>
        {data.map((row: any) => (
          <rect
            key={uniqueId("bar-")}
            x={xScale(accessors.x(row))}
            y={yScale(accessors.y(row))}
            height={yScale(accessors.y0(row)) - yScale(accessors.y(row))}
            width={width || xScale.bandwidth()}
            fill={color}
            {...(tooltip
              ? getEventHandlers(
                  tooltipRef,
                  accessors.y(row) - accessors.y0(row)
                )
              : {})}
          >
            <title></title>
          </rect>
        ))}
      </g>
      {tooltip &&
        React.cloneElement(tooltip, {
          ref: (ref: any) => (tooltipRef.current = ref),
        })}
    </>
  );
};
Bar.getScaleDefs = ({
  x,
  y,
  data,
  paddingInner = 0.25,
  paddingOuter = 0.125,
}: BarProps) => ({
  x: x ? { type: "band", key: x, data, paddingInner, paddingOuter } : undefined,
  y: y ? { type: "linear", key: y, data } : undefined,
});
