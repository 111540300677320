import React from "react";
import uniqueId from "lodash.uniqueid";
import { FormControl, Inline, Select } from "@airbus/components-react";
import {
  useCountryNames,
  useCountryStats,
  useParams,
  useStore,
  isRegion,
} from "../data/store";
import MetricCard from "./ui/MetricCard";

const SingleScenarioModeParams = () => {
  const params = useParams();
  const setParams = useStore((state) => state.setParams);
  const countries = useCountryNames();
  const departureCountryStats = useCountryStats(params.departure_country);
  const arrivalCountryStats = useCountryStats(params.arrival_country);
  return (
    <Inline spacing="2-x">
      <FormControl label="Departure Country" required>
        <Select
          size="small"
          value={params.departure_country}
          onChange={(event: any) =>
            setParams({ departure_country: event.target.value })
          }
        >
          {countries.map((name: string) => (
            <option key={`country-${uniqueId()}`} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>
      <MetricCard
        small
        title="14 day Incidence"
        number={departureCountryStats.prevalence.toFixed(0)}
      />
      <MetricCard
        small
        title="% Vaccinated"
        number={departureCountryStats.vaccinated_population.toFixed(0)}
      />
      <MetricCard
        small
        title="Transmission Rate"
        number={
          isRegion(departureCountryStats)
            ? "-"
            : departureCountryStats.r0_latest.toFixed(2)
        }
        trend={departureCountryStats.r0_percent_change}
      />
      <FormControl label="Arrival Country" required>
        <Select
          size="small"
          value={params.arrival_country}
          onChange={(event: any) =>
            setParams({ arrival_country: event.target.value })
          }
        >
          {countries.map((name: string) => (
            <option key={`country-${uniqueId()}`} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>
      <MetricCard
        small
        title="14 day Incidence"
        number={arrivalCountryStats.prevalence.toFixed(0)}
      />
      <MetricCard
        small
        title="% Vaccinated"
        number={arrivalCountryStats.vaccinated_population.toFixed(0)}
      />
      <MetricCard
        small
        title="Transmission Rate"
        number={
          isRegion(arrivalCountryStats)
            ? "-"
            : arrivalCountryStats.r0_latest.toFixed(2)
        }
        trend={arrivalCountryStats.r0_percent_change}
      />
    </Inline>
  );
};

export default SingleScenarioModeParams;
