const config = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USERPOOL_CLIENTID,
    oauth: {
      domain: process.env.REACT_APP_AWS_USERPOOL_DOMAIN,
      scope: [
        "email",
        "profile",
        "openid",
        "phone",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code",
    },
  },
};

export default config;
