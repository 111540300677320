export type MarkProps = {
  data: any;
  x?: string;
  y?: string;
};

export const getValueAccessors = (props: any) => {
  const { x, y } = props;
  return {
    x: x ? (row: any) => row[x] : (row: any) => row.x,
    y: y ? (row: any) => row[y] : (row: any) => row.y,
    x0: (row: any) => row.x0 || 0,
    y0: (row: any) => row.y0 || 0,
  };
};
