import React from "react";
import {
  Stack,
  ComboBox,
  createUseStyles,
  Link,
  Inline,
} from "@airbus/components-react";
import ChartCard from "./ui/ChartCard";
import {
  useCountryStats,
  useParams,
  useRegionCountryNames,
  useSelectedCountries,
  useStatsQuery,
  useStore,
  useTestScenario,
  useTrafficData,
} from "../data/store";
import TestStrategyComparison from "./TestStrategyComparison";
import { getHiddenContagiousFromStats } from "./SingleScenarioTab";
import { processScenarioTravelRisk, processTrafficRisk } from "../data";
import {
  Chart,
  Legend,
  PieChart,
  sum,
  XAxis,
  YAxis,
  Stack as Stacked,
  Bar,
  RuleY,
  Tooltip,
} from "./charting";

const useStyles = createUseStyles((theme: any) => {
  return {
    combobox: {
      maxHeight: 100,
      overflow: "auto",
    },
  };
}, "explorationtab");

const ExplorationTab = () => {
  const classes = useStyles();
  const params = useParams();
  const scenario = useTestScenario(params.test_scenario);
  const selectableCountries = useRegionCountryNames(params.departure_region);
  const selectedCountries = useSelectedCountries();
  const setSelectedCountries = useStore((state) => state.selectCountries);
  const departureRegionDataByCountry = useStatsQuery(
    (r: any) => r.region === params.departure_region
  ).filter((r: any) => selectedCountries.indexOf(r.country_name) !== -1);
  const destinationCountryData = useCountryStats(params.destination_country);
  const trafficDataByCountry = useTrafficData(
    selectedCountries,
    params.destination_country
  );
  const travelRiskByCountry = processScenarioTravelRisk(
    scenario,
    departureRegionDataByCountry,
    params
  );
  const travelRiskByCountryPer100000 = travelRiskByCountry.map((row: any) => ({
    country_name: row.country_name,
    travel_risk: Math.round(row.travel_risk * 100000),
    hidden_contagious_rate: getHiddenContagiousFromStats(row, params) * 100000,
    total: Math.round(
      row.travel_risk * 100000 +
        getHiddenContagiousFromStats(row, params) * 100000
    ),
  }));
  const trafficRiskByCountry = processTrafficRisk(
    travelRiskByCountry,
    destinationCountryData,
    trafficDataByCountry,
    params
  );
  const totalTrafficRisk = sum(
    trafficRiskByCountry,
    (row: any) => row.traffic_risk
  );
  const destinationIncidence = Number(
    destinationCountryData.prevalence.toFixed(0)
  );

  return (
    <Stack spacing="2-x" style={{ paddingBottom: 20 }}>
      <ComboBox
        className={classes.combobox}
        multiple
        value={selectedCountries}
        onChange={(values: any) => setSelectedCountries(values)}
        options={selectableCountries}
      />
      <Inline>
        <Link onClick={() => setSelectedCountries(selectableCountries)}>
          Select all
        </Link>
        <Link onClick={() => setSelectedCountries([])}>Unselect all</Link>
      </Inline>
      <ChartCard
        title="Relative Risk Assessment: Hidden Contagious + Travel Risk per 100,000"
        explainer="To compare with 14 day incidence of arrival country (reference selected)"
        legend={
          <Legend
            data={["risk from travelling", "hidden contagious"]}
            colorScheme="qualitative"
          />
        }
      >
        <Chart margin={{ top: 10, right: 10, bottom: 140, left: 80 }}>
          <XAxis label="Country" rotateTickLabels />
          <YAxis label="People per 100000" grid />
          <Stacked colorScheme="qualitative">
            <Bar
              x="country_name"
              y="travel_risk"
              data={travelRiskByCountryPer100000}
            />
            <Bar
              x="country_name"
              y="total"
              data={travelRiskByCountryPer100000}
            />
          </Stacked>
          <RuleY y={[destinationIncidence]} />
        </Chart>
      </ChartCard>
      <ChartCard
        title="Absolute Risk Traffic-Wise: Overall Risk * Traffic / Destination Population per 100,000"
        explainer="Takes into account the air traffic data over two weeks from selected departure countries to destination country"
        legend={
          <Legend
            data={["overall risk from travelling"]}
            colors={["#255FCC"]}
          />
        }
      >
        <Chart>
          <XAxis label="Country" rotateTickLabels />
          <YAxis label="People per 100000" grid />
          <Bar
            x="country_name"
            y="traffic_risk"
            data={trafficRiskByCountry}
            tooltip={<Tooltip />}
          />
          <RuleY y={[destinationIncidence]} />
        </Chart>
      </ChartCard>
      <ChartCard
        title="Absolute Risk Traffic-Wise: Overall Risk * Traffic / Destination Population per 100,000"
        explainer="Takes into account the air traffic data over two weeks from selected departure countries to destination country"
        legend={
          <Legend
            data={["14 day imported cases", "14 day destination incidence"]}
            colorScheme="highlight"
          />
        }
      >
        <PieChart
          data={[totalTrafficRisk, destinationIncidence]}
          colorScheme="highlight"
        />
      </ChartCard>
      <TestStrategyComparison selectedCountries={selectedCountries} />
    </Stack>
  );
};

export default ExplorationTab;
