import { StepFunction } from "./types";

// flight/seated
export const step10: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  let P_total = 0,
    P_FF1 = 0,
    P_FF2 = 0,
    P_FC1 = 0,
    P_FF3_FF4 = 0,
    P_FF5 = 0,
    P_FC2 = 0;

  // probability that hands get infected from seat
  const P_FF1_s1 =
    (1 - (1 - hidden_contagious) ** params.boarding.pax_seated_closeby) *
    params.hand_infection.incorrect_usage *
    params.hand_infection.pax_touch_seat *
    params.hand_infection.poor_seat_cleaning *
    params.hand_infection.same_touch_seat *
    params.hand_infection.incorrect_usage;

  const P_FF1_s2 =
    ((hidden_contagious *
      (1 -
        params.hand_infection.poor_seat_cleaning **
          params.hand_infection.nbr_flights_since_cleaning)) /
      (1 - params.hand_infection.poor_seat_cleaning)) *
    params.hand_infection.poor_seat_cleaning *
    params.hand_infection.touch_seat_prev_flight *
    params.hand_infection.poor_hand_cleaning;

  // probability to touch face
  P_FF2 =
    (1 - collective_immunity) *
    params.mask_proba.ratio_touch_mask *
    Math.min(
      1,
      params.flight_time * 60 * params.mask_proba.rate_min_touch_face
    );

  P_FF1 = P_FF1_s1 + P_FF1_s2;

  // probability of FC1
  P_FC1 = (P_FF1_s1 + P_FF1_s2) * P_FF2 * params.measures.fomites_factor;

  // probability that there is a contagious person nearby
  P_FF3_FF4 = hidden_contagious;

  // probability that not protected against contamination
  // note: UPDATE of mask reduction efficiency!
  P_FF5 =
    (((1 - collective_immunity) * 1 * params.mask_proba.eff_reduction1) /
      params.measures.air_con_risk_red) *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) **
        (params.flight_time * 60 - params.time.time_eating)) *
    params.boarding.pax_seated_closeby;

  // probability of FC2
  P_FC2 = P_FF3_FF4 * P_FF5;

  // calculate total risk
  P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
