import React from "react";
import { createUseStyles, useTheme } from "@airbus/components-react";
import clsx from "clsx";
import { scaleLinear } from "../charting/utils";

const useStyles = createUseStyles((theme: any) => {
  const { elevation, color } = theme;
  return {
    root: {
      boxShadow: elevation.level1,
      borderTop: `3px solid ${color.common.brand.secondary}`,
      maxWidth: "initial",
      flex: "1 0 0",
      "&:not(:last-child)": {
        marginRight: 16,
      },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "12px 8px",
    },
    label: {
      fontSize: "1.0rem",
      lineHeight: "1.5rem",
      marginBottom: "0.5rem",
    },
    metric: {
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
      display: "flex",
      alignItems: "center",
    },
    small: {
      borderTop: "none",
      padding: 6,
      "& $label": {
        fontSize: "0.8rem",
        lineHeight: "1.2rem",
        marginBottom: "0.3rem",
      },
      "& $metric": {
        fontSize: "1.2rem",
        lineHeight: "1.2rem",
      },
    },
  };
}, "metric");

const MetricCard = ({ title, number, small = false, trend = false }: any) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, small && classes.small)}>
      <div className={classes.label}>{title}</div>
      <div className={classes.metric}>
        {number}
        {trend && trend !== 0 && <TrendLine trend={trend} />}
      </div>
    </div>
  );
};

const TrendLine = ({ trend }: any) => {
  const theme = useTheme();
  const scale = scaleLinear().domain([-100, 100]).range([0, 20]);
  return (
    <svg width="20" height="20" viewBox={"0,0,20,20"} style={{ marginLeft: 5 }}>
      <line
        x1="0"
        x2="20"
        y1="10"
        y2={scale(trend)}
        stroke={theme.color.common.brand.secondary}
        strokeWidth={"2"}
      />
    </svg>
  );
};

export default MetricCard;
