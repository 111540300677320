import React, { ReactNode } from "react";
import uniqueId from "lodash.uniqueid";
import { Tooltip } from ".";
import { Bar } from "./Bar";
import { createScale, inferScalesFromChildren } from "./Chart";
import { ColorSchemeName, useColorScheme } from "./colorSchemes";
import { max, min, stack } from "./utils";

type StackProps = {
  /* the color scheme */
  colorScheme?: ColorSchemeName;
  children: ReactNode;
};

export const Stack = ({
  colorScheme: colorSchemeName = "cool",
  children,
}: StackProps) => {
  const colorScheme = useColorScheme(
    colorSchemeName,
    React.Children.count(children)
  );

  let keys: string[] = [];
  let x: string;
  let data: any;
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }
    keys.push(child.props.y);
    // we should check if all datasets are the same, or make marks data prop optional?
    data = child.props.data;
    x = child.props.x;
  });

  const series = stack().keys(keys)(data);
  const colorScale = createScale(
    {
      type: "ordinal",
      domain: keys,
    },
    colorScheme
  );

  return (
    <g>
      {series.map((data) => (
        <Bar
          key={uniqueId("bar-series-")}
          data={data.map((v) => ({ x: v.data[x], y: v[1], y0: v[0] }))}
          color={colorScale(data.key as any) as string}
          tooltip={<Tooltip />}
        />
      ))}
    </g>
  );
};
Stack.getScaleDefs = ({ children }: StackProps) => {
  const childrenScaleDefs = inferScalesFromChildren(children);
  // we should check if all scales are compatible, ie same type
  return {
    x: childrenScaleDefs.x[0],
    y: {
      ...childrenScaleDefs.y[0],
      domain: [
        min(childrenScaleDefs.y, (def) => def.domain[0]),
        max(childrenScaleDefs.y, (def) => def.domain[1]),
      ],
    },
  };
};
