import { StepFunction } from "./types";

// entering
export const step9: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  let P_total = 0,
    P_FF1 = 0,
    P_FF2 = 0,
    P_FC1 = 0,
    P_FF3_FF4 = 0,
    P_FF5 = 0,
    P_FC2 = 0;

  // probability that hands get infected from rail
  // The calculation of P_FF1 is simplified using a formula that depends on the number of people boarding simulateously and the hidden contagious rate, to avoid using a factorial-based calculation (=12*hidden_contagious*nbr_ppl_boarding/25)

  P_FF1 =
    ((12 * hidden_contagious * params.boarding.simultaneous_people) / 25 +
      params.boarding.simultaneous_people *
        params.hand_infection.nbr_flights_since_cleaning *
        // TODO: we use this additional param because in the original code it uses q_data.hidden_contagious_rate which stays the same even if the mode changes
        // Check with Anna to see why
        params.world_hidden_contagious_rate) *
    params.hand_infection.incorrect_usage *
    params.hand_infection.same_touch_seat *
    params.boarding.touch_other_seats *
    params.hand_infection.incorrect_usage;

  // probability to touch face
  P_FF2 =
    (1 - collective_immunity) *
    params.mask_proba.ratio_touch_mask *
    Math.min(
      1,
      params.time.time_to_seat * params.mask_proba.rate_min_touch_face
    );

  // probability of FC1
  P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

  // probability that there is a contagious person nearby
  P_FF3_FF4 = hidden_contagious;

  // probability that not protected against contamination
  // note: UPDATE of mask reduction efficiency!
  P_FF5 =
    (((1 - collective_immunity) *
      ground_disrespect *
      params.mask_proba.eff_reduction1) /
      params.measures.air_con_risk_red) *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) **
        params.time.time_to_seat) *
    params.boarding.pax_get_to_seat_together;

  // probability of FC2
  P_FC2 = P_FF3_FF4 * P_FF5;

  // calculate total risk
  P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
