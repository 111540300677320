import React from "react";
import { Stack } from "@airbus/components-react";
import OverallRiskAssessment from "./OverallRiskAssessment";
import ProbabilitiesByStep from "./ProbabilitiesByStep";
import { useParams, useCountryStats } from "../data/store";
import { processInfectionProbabilities } from "../data/steps";
import FourteenDayRates from "./FourteenDayRates";

const getHiddenContagiousFromParams = (params: any) => {
  const lethality_rate_ref = 0.0075;
  const lethality_rate = params.leth_rate;
  const cases = params.incidence;
  const population = params.population * 1000000;
  const hidden_factor = lethality_rate / lethality_rate_ref;
  return (cases * (12 / 14) * (0.4 / 0.6) * 0.75 * hidden_factor) / population;
};

export const getHiddenContagiousFromStats = (stats: any, params: any) => {
  const lethality_rate_ref = 0.0075;
  const measured_lethality = Number(stats.lethality_calculated);
  const infection_lethality =
    ((1 -
      ((1 -
        (1 - params.vaccine_infection_eff / 100) *
          (1 - params.vaccine_disease_eff / 100)) *
        stats.vaccinated_population) /
        100) /
      (1 -
        ((params.vaccine_infection_eff / 100) * stats.vaccinated_population) /
          100)) *
    lethality_rate_ref;

  const cases = Number(stats.sum_new_cases_14d);
  const population = stats.population;
  const hidden_factor = measured_lethality / infection_lethality;
  return (cases * (12 / 14) * (0.4 / 0.6) * 0.75 * hidden_factor) / population;
};

const SingleScenarioTab = () => {
  const params = useParams();
  const departureCountryStats = useCountryStats(params.departure_country);

  let { hidden_contagious_rate, collective_immunity, ground_disrespect } =
    departureCountryStats;

  if (params.manual_stats) {
    hidden_contagious_rate = getHiddenContagiousFromParams(params);
  } else {
    hidden_contagious_rate = getHiddenContagiousFromStats(
      departureCountryStats,
      params
    );
  }

  const probas = processInfectionProbabilities(
    hidden_contagious_rate,
    collective_immunity,
    ground_disrespect,
    params
  );

  return (
    <Stack spacing="2-x" style={{ paddingBottom: 20 }}>
      <OverallRiskAssessment
        hiddenContagiousRate={hidden_contagious_rate}
        probabilitiesByStep={probas}
      />
      <FourteenDayRates />
      <ProbabilitiesByStep probabilitiesByStep={probas} />
    </Stack>
  );
};

export default SingleScenarioTab;
