import React from "react";
import ResizeObserver from "resize-observer-polyfill";
import { scaleLinear, scaleBand, max, scaleOrdinal } from "./utils";

const defaultDimensions = {
  margin: { top: 10, right: 10, bottom: 80, left: 80 },
  height: 400,
  width: 1040,
};

export type ChartDimensions = {
  margin: any;
  height: number;
  width: number;
};

export const useResponsiveDimensions = (
  passedSettings?: Partial<ChartDimensions>
) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const dimensions = passedSettings
    ? { ...defaultDimensions, ...passedSettings }
    : defaultDimensions;
  const [width, setWidth] = React.useState(dimensions.width);
  React.useEffect(() => {
    const element = ref.current;
    if (element === null) return;
    const resizeObserver = new ResizeObserver((entries: any) => {
      if (!Array.isArray(entries)) return;
      if (!entries.length) return;
      const entry = entries[0];
      setWidth(entry.contentRect.width);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);
  }, []);
  const newSettings = {
    ...dimensions,
    width,
  };
  return [ref, newSettings] as [
    React.MutableRefObject<HTMLDivElement>,
    ChartDimensions
  ];
};

type Domain = any[];
type Range = [number, number] | string[];
type ScaleType = "linear" | "band" | "ordinal";

export type ScaleProps = {
  type?: ScaleType;
  domain: Domain;
  padding?: number;
  paddingInner?: number;
  paddingOuter?: number;
};

export const createScale = (props: ScaleProps, range: Range) => {
  switch (props.type) {
    case "band":
      const scale = scaleBand()
        .domain(props.domain)
        .range(range as [number, number]);
      if (props.padding) scale.padding(props.padding);
      if (props.paddingInner) scale.paddingInner(props.paddingInner);
      if (props.paddingOuter) scale.paddingOuter(props.paddingOuter);
      return scale;
    case "ordinal":
      return scaleOrdinal().domain(props.domain).range(range);
    default:
      return scaleLinear()
        .domain(props.domain)
        .nice()
        .rangeRound(range as [number, number]);
  }
};

const inferDomain = (props: MarkScaleProps): Domain => {
  const { type, key, data } = props;
  switch (type) {
    case "ordinal":
    case "band":
      return data.map((row: any) => row[key!] as string);
    default:
      return [0, max(data, (row: any) => row[key!] as number) as number];
  }
};

const convertMarkToChartScale = (props: MarkScaleProps): ScaleProps => {
  const { type, key, data, domain = inferDomain(props), ...rest } = props;
  return {
    type,
    domain,
    ...rest,
  };
};

type ChartProps = Partial<ChartDimensions> & {
  data?: any;
  x?: ScaleProps;
  y?: ScaleProps;
  children: React.ReactNode;
};

type Mark = {
  getScaleDefs: (props: any) => { x?: MarkScaleProps; y?: MarkScaleProps };
};

type MarkScaleProps = {
  type?: ScaleType;
  key: string;
  data: any;
  domain?: Domain;
  padding?: number;
};

type ChartContextDef = {
  dimensions: ChartDimensions;
  scales: { x: any; y: any };
  ranges: { x: Range; y: Range };
};

const ChartContext = React.createContext<ChartContextDef>({
  dimensions: defaultDimensions,
  scales: { x: () => {}, y: () => {} },
  ranges: { x: [0, 0], y: [0, 0] },
});

export const useChartDimensions = () =>
  React.useContext(ChartContext).dimensions;
export const useChartScales = () => React.useContext(ChartContext).scales;
export const useChartRanges = () => React.useContext(ChartContext).ranges;

export const inferScalesFromChildren = (children: React.ReactNode) => {
  const possibleScales: { x: ScaleProps[]; y: ScaleProps[] } = { x: [], y: [] };
  React.Children.forEach(children, (child) => {
    if (
      !React.isValidElement(child) ||
      !child.type.hasOwnProperty("getScaleDefs")
    ) {
      return;
    }
    const possibleMark = child.type as unknown as Mark;
    const { x, y } = possibleMark.getScaleDefs(child.props);
    if (x !== undefined) possibleScales.x.push(convertMarkToChartScale(x));
    if (y !== undefined) possibleScales.y.push(convertMarkToChartScale(y));
  });
  return possibleScales;
};

export const Chart: React.FC<ChartProps> = ({
  x,
  y,
  data,
  children,
  ...rest
}) => {
  const [ref, { margin, width, height }] = useResponsiveDimensions(rest);
  const ranges: { x: Range; y: Range } = {
    x: [margin.left, width - margin.right],
    y: [height - margin.bottom, margin.top],
  };
  const childrenScaleDefs = inferScalesFromChildren(children);
  if (childrenScaleDefs.x.length > 1 || childrenScaleDefs.y.length > 1) {
    throw new Error("Multiple scales on a single axis is not supported");
  }
  const scaleDefs = {
    x: x || childrenScaleDefs.x[0],
    y: y || childrenScaleDefs.y[0],
  }; // better to merge scale props?
  if (!scaleDefs.x || !scaleDefs.y) {
    throw new Error("No scale found for one or both axis");
  }
  const scales = React.useMemo(
    () => ({
      x: createScale(scaleDefs.x!, ranges.x),
      y: createScale(scaleDefs.y!, ranges.y),
    }),
    [scaleDefs, ranges]
  );
  return (
    <div ref={ref}>
      <svg width={width} height={height} viewBox={`0,0,${width},${height}`}>
        <ChartContext.Provider
          value={{ dimensions: { margin, width, height }, scales, ranges }}
        >
          {children}
        </ChartContext.Provider>
      </svg>
    </div>
  );
};
