import React from "react";
import { useTheme } from "@airbus/components-react";
import uniqueId from "lodash.uniqueid";
import { useChartDimensions, useChartScales, useChartRanges } from "./Chart";

const useAxisStyles = () => {
  const { font, color } = useTheme();
  return {
    axis: {
      color: color.text.tertiary,
    },
    axisLabel: {
      fontFamily: "inherit",
      fontSize: font.size.medium,
      fontWeight: font.weight.bold,
      lineHeight: font.lineheight.medium,
      fill: color.text.tertiary,
    },
    tickLabel: {
      fontFamily: "inherit",
      fontSize: "12px",
      fill: "currentColor",
    },
  };
};

export const XAxisLabel = ({ label }: { label: string }) => {
  const { axisLabel } = useAxisStyles();
  const { width, height } = useChartDimensions();
  return (
    <text
      transform={`translate(${width / 2} ${height - 12})`}
      style={{ ...axisLabel, textAnchor: "middle" }}
    >
      {label}
    </text>
  );
};

export const YAxisLabel = ({ label }: { label: string }) => {
  const { axisLabel } = useAxisStyles();
  const { height } = useChartDimensions();
  return (
    <text
      transform={`rotate(-90)`}
      y={12}
      x={0 - height / 2}
      style={{ ...axisLabel, textAnchor: "middle" }}
    >
      {label}
    </text>
  );
};

type AxisProps = {
  ticks?: boolean;
  tickValues?: any[];
  ticksTarget?: number;
  tickFormat?: number | ((tick: any) => string);
  /* Whether or not to display grid lines */
  grid?: boolean;
  /* Whether or not to display grid bands */
  bands?: boolean;
  label?: string;
  rotateTickLabels?: boolean;
};

const defaultTickFormat = (tick: any) => tick;

export const XAxis = ({
  ticks = false,
  tickValues,
  ticksTarget,
  tickFormat = defaultTickFormat,
  label,
  bands = true,
  rotateTickLabels = false,
}: AxisProps) => {
  const styles = useAxisStyles();
  const { x: xScale } = useChartScales();
  const { x: xRange, y: yRange } = useChartRanges();

  const xTicks =
    tickValues ||
    (xScale.ticks && xScale.ticks(ticksTarget)) ||
    xScale.domain();

  const tickFormatter =
    typeof tickFormat === "number" ? xScale.tickFormat(tickFormat) : tickFormat;

  const xBandsScale = xScale.copy().padding(0);

  return (
    <>
      {bands && (
        <g>
          {xBandsScale
            .domain()
            .map((value: any, index: number) =>
              index % 2 === 0 ? (
                <rect
                  key={uniqueId("gridbar-")}
                  x={xBandsScale(value)}
                  y={yRange[1]}
                  height={(yRange[0] as number) - (yRange[1] as number)}
                  width={xBandsScale.bandwidth()}
                  fill={"#F7F9FA"}
                />
              ) : null
            )}
        </g>
      )}
      <g transform={`translate(0,${yRange[0]})`} color={styles.axis.color}>
        <path
          d={["M", xRange[0], 0, "H", xRange[1]].join(" ")}
          fill="none"
          stroke="currentColor"
        />
        {xTicks.map((value: any, index: number) => (
          <g
            key={uniqueId("tick-")}
            transform={`translate(${xScale(value) || 0}, 0)`}
          >
            <g transform={`translate(${xScale.bandwidth() / 2}, 0)`}>
              {ticks && <line y2="6" stroke="currentColor" />}
              {!rotateTickLabels && (
                <text
                  key={uniqueId("text-")}
                  y="8"
                  dy="16"
                  style={{ ...styles.tickLabel, textAnchor: "middle" }}
                >
                  {wrapLabel(tickFormatter(value), xBandsScale.bandwidth()).map(
                    (word, index) => (
                      <tspan
                        key={uniqueId("tspan-")}
                        x={0}
                        y="20"
                        dy={index === 0 ? 0 : 14}
                      >
                        {word}
                      </tspan>
                    )
                  )}
                </text>
              )}
              {rotateTickLabels && (
                <text
                  key={uniqueId("text-")}
                  style={{
                    ...styles.tickLabel,
                    textAnchor: "start",
                    transform: `rotate(90deg) translate(10px,${
                      (xScale.bandwidth() - 10) / 2 < 5 ? 3 : 5
                    }px)`,
                  }}
                >
                  {tickFormatter(value)}
                </text>
              )}
            </g>
          </g>
        ))}
      </g>
      {label && <XAxisLabel label={label} />}
    </>
  );
};

export const YAxis = ({
  ticks = false,
  tickValues,
  ticksTarget,
  tickFormat = defaultTickFormat,
  grid = false,
  label,
}: AxisProps) => {
  const styles = useAxisStyles();
  const { y: yScale } = useChartScales();
  const { x: xRange, y: yRange } = useChartRanges();

  const yTicks =
    tickValues ||
    (yScale.ticks && yScale.ticks(ticksTarget)) ||
    yScale.domain();

  const tickFormatter =
    typeof tickFormat === "number" ? yScale.tickFormat(tickFormat) : tickFormat;

  return (
    <>
      <g transform={`translate(${xRange[0]}, 0)`} color={styles.axis.color}>
        <path
          d={["M", 0, yRange[0], "V", yRange[1]].join(" ")}
          fill="none"
          stroke="currentColor"
        />
        {yTicks.map((value: any, index: number) => (
          <g
            key={uniqueId("tick-")}
            transform={`translate(0, ${yScale(value)})`}
          >
            {grid && index !== 0 && (
              <path
                d={[
                  "M",
                  0,
                  0,
                  "H",
                  (xRange[1] as number) - (xRange[0] as number),
                ].join(" ")}
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeDasharray="1, 5"
              />
            )}
            {ticks && <line x2="-6" stroke="currentColor" />}
            <text
              key={uniqueId("text-")}
              x="-8"
              dy="0.3rem"
              style={{
                ...styles.tickLabel,
                textAnchor: "end",
              }}
            >
              {tickFormatter(value)}
            </text>
          </g>
        ))}
      </g>
      {label && <YAxisLabel label={label} />}
    </>
  );
};

function getTextWidth(text: string, font = "500 12px sans-serif") {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  context.font = font;
  return context.measureText(text).width;
}

function breakString(word: string, maxWidth: number, hyphenCharacter = "-") {
  const characters = word.split("");
  const lines: string[] = [];
  let currentLine = "";
  characters.forEach((character, index) => {
    const nextLine = `${currentLine}${character}`;
    const lineWidth = getTextWidth(nextLine);
    if (lineWidth >= maxWidth) {
      const currentCharacter = index + 1;
      const isLastLine = characters.length === currentCharacter;
      const hyphenatedNextLine = `${nextLine}${hyphenCharacter}`;
      lines.push(isLastLine ? nextLine : hyphenatedNextLine);
      currentLine = "";
    } else {
      currentLine = nextLine;
    }
  });
  return { hyphenatedStrings: lines, remainingWord: currentLine };
}

function wrapLabel(label: string, maxWidth: number) {
  const words = label.split(" ");
  const completedLines: string[] = [];
  let nextLine = "";
  words.forEach((word, index) => {
    const wordLength = getTextWidth(`${word} `);
    const nextLineLength = getTextWidth(nextLine);
    if (wordLength > maxWidth) {
      const { hyphenatedStrings, remainingWord } = breakString(word, maxWidth);
      completedLines.push(nextLine, ...hyphenatedStrings);
      nextLine = remainingWord;
    } else if (nextLineLength + wordLength >= maxWidth) {
      completedLines.push(nextLine);
      nextLine = word;
    } else {
      nextLine = [nextLine, word].filter(Boolean).join(" ");
    }
    const currentWord = index + 1;
    const isLastWord = currentWord === words.length;
    if (isLastWord) {
      completedLines.push(nextLine);
    }
  });
  return completedLines.filter((line) => line !== "");
}
