// User-defined params
export const defaultParams: { [index: string]: any } = {
  time_at_airport: 2,
  flight_time: 2,
  checkin: "kiosk", // or counter or online
  immigration: "kiosk", // or none or counter
  catering: false,
  test_scenario: 0,
  // advanced section
  // ----------------
  num_pax: 200,
  pax_boarding: 24,
  seats_gate: 100,
  seats_blocked: 50,
  flights_using_gate: 10,
  fomites_factor: 1,
  air_con_eff: 5,
  sneezing_rate: 6,
  mask_eff_red1: 80,
  time_eating: 20,
  flights_s_cleaning: 0,
  pax_eating: 100,
  // Consider immunity
  immunity: false,
  // % population vaccinated
  vaccination: 5,
  all_pax_vaccinated: false,
  // vaccine_transmission_reduction: 50,
  vaccine_infection_eff: 62,
  vaccine_disease_eff: 75,
  manual_stats: false,
  incidence: 5000,
  leth_rate: 0.75,
  population: 1.56,
  // single scenario
  // --------------------
  departure_country: "World",
  arrival_country: "France",
  // exploration
  //--------------------
  departure_region: "Europe",
  destination_country: "France",
  traffic_percent: 100,
};

export default function processParams(params: any) {
  var mask_proba = {
    rate_coughing_sneezing: params.sneezing_rate / (24 * 60),
    eff_reduction1: 1 - params.mask_eff_red1 / 100,
    rate_min_touch_face: 2,
    ratio_touch_mask: 0.5,
    ratio_touch_after_board: 0.5,
    ratio_touch_catering: 1,
    staff_mask_defect: 0.0000001,
    fail_catering: 1,
    fail_eat_sneeze: 0.5,
  };

  var ground_marking = { counter_disrespect: 0.001, toilet_disrespect: 0.01 };

  var hand_infection = {
    touch_contactless: 0.1,
    incorrect_usage: 0.1,
    same_touch_dispenser: 0.01,
    not_disinfecting: 0.1,
    poor_surface_cleaning: 0.1,
    poor_hand_cleaning: 0.1,
    staff_not_disinfected: 0.01,
    counter_touch: 0.5,
    same_touch_counter: 0.5,
    rail_touch: 0.6,
    poor_seat_cleaning: 0.3,
    pax_touch_seat: 0.5,
    same_touch_seat: 0.05,
    touch_seat_prev_flight: 0.9,
    meal_contamination: 0.000000001,
    nbr_flights_since_cleaning: params.flights_s_cleaning,
  };

  var counters = { glass_defect: 0.00005 };

  var security_check = { number_trays: 20 };

  var gate = {
    seats: params.seats_gate,
    blocked_seats: params.seats_blocked / 100,
    flights_used: params.flights_using_gate,
    no_social_distance: 0.3,
    ppl_closeby: 2,
  };

  var boarding = {
    no_social_distance: 0.3,
    simultaneous_people: params.pax_boarding,
    touch_other_seats: 0.3,
    pax_seated_closeby: 2,
    pax_met_to_toilet: 30,
    pax_get_to_seat_together: 3,
    pax_eating_same_time: params.pax_eating / 100,
  };

  var time = {
    time_BTE_queue: 0.025 * params.time_at_airport * 60,
    time_BTE: 0.05 * params.time_at_airport * 60,
    time_kiosk_queue: 0.025 * params.time_at_airport * 60,
    time_kiosk: 0.05 * params.time_at_airport * 60,
    time_checkin_counter_queue: 0.075 * params.time_at_airport * 60,
    timeat_checkin_counter: 5,
    time_checkin_counter: 0.05 * params.time_at_airport * 60,
    time_security_queue: 0.05 * params.time_at_airport * 60,
    time_security: 0.05 * params.time_at_airport * 60,
    time_epassport_queue: 0.025 * params.time_at_airport * 60,
    time_epassport: 0.05 * params.time_at_airport * 60,
    time_immigration_queue: 0.075 * params.time_at_airport * 60,
    timeat_immigration: 2,
    time_immigration: 0.05 * params.time_at_airport * 60,
    time_gate: 0.4 * params.time_at_airport * 60,
    time_boarding_queue: Math.min(
      (0.025 * params.time_at_airport * 60 * params.pax_boarding) / 25,
      10
    ),
    timeat_boarding: 1,
    time_boarding: 0.025 * params.time_at_airport * 60,
    time_airbridge_queue: Math.min(
      (0.025 * params.time_at_airport * 60 * params.pax_boarding) / 25,
      10
    ),
    time_door_queue: 2,
    time_to_seat: Math.min((3 * params.pax_boarding) / 25, 10),
    time_eating: params.catering ? params.time_eating : 0,
    time_to_toilet: 0.05,
    time_toilet: 3,
  };

  var time_sum =
    time.time_BTE_queue * 2 +
    time.time_BTE * 2 +
    time.time_kiosk_queue +
    time.time_kiosk +
    time.time_security_queue +
    time.time_security +
    time.time_epassport_queue +
    time.time_epassport +
    time.time_gate +
    time.time_boarding_queue * 2 +
    time.timeat_boarding * 2 +
    time.time_boarding * 2 +
    time.time_airbridge_queue;

  var time_sum_2 =
    time.time_BTE_queue * 2 +
    time.time_BTE * 2 +
    time.time_checkin_counter_queue +
    time.timeat_checkin_counter +
    time.time_checkin_counter +
    time.time_security_queue +
    time.time_security +
    time.time_immigration_queue +
    time.timeat_immigration +
    time.time_immigration +
    time.time_gate +
    time.time_boarding_queue * 2 +
    time.timeat_boarding * 2 +
    time.time_boarding * 2 +
    time.time_airbridge_queue;

  var measures = {
    air_con_risk_red: params.air_con_eff,
    fomites_factor: params.fomites_factor,
  };

  return {
    ...params,
    mask_proba: mask_proba,
    ground_marking: ground_marking,
    hand_infection: hand_infection,
    counters: counters,
    security_check: security_check,
    gate: gate,
    boarding: boarding,
    time: time,
    measures: measures,
    time_sum,
    time_sum_2,
  };
}
