import React from "react";
import { StepInfectionProbabilities } from "../data/steps/types";
import ChartCard from "./ui/ChartCard";
import { Bar, Chart, Group, Legend, Tooltip, XAxis, YAxis } from "./charting";

type Props = {
  probabilitiesByStep: StepInfectionProbabilities[];
};

const ProbabilitiesByStep = ({ probabilitiesByStep }: Props) => {
  const data = probabilitiesByStep.map(({ name, values }) => ({
    name,
    fomites: values.P_FC1,
    droplets: values.P_FC2,
    total: values.P_total,
  }));

  return (
    <ChartCard
      title="Probabilities by Step"
      explainer="Analysis at each terminal and aircraft event"
      legend={
        <Legend
          data={["fomites", "droplets", "total"]}
          colorScheme="qualitative"
        />
      }
    >
      <Chart>
        <XAxis label="Terminal / Aircraft Location" />
        <YAxis label="Probability to get Covid19" grid tickFormat={10} />
        <Group colorScheme="qualitative">
          <Bar x="name" y="fomites" data={data} tooltip={<Tooltip />} />
          <Bar x="name" y="droplets" data={data} tooltip={<Tooltip />} />
          <Bar x="name" y="total" data={data} tooltip={<Tooltip />} />
        </Group>
      </Chart>
    </ChartCard>
  );
};

export default ProbabilitiesByStep;
