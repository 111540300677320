import React from "react";
import {
  Button,
  ButtonGroup,
  createUseStyles,
  FormControl,
  Select,
  Toggle,
} from "@airbus/components-react";
import Slider from "./ui/Slider";
import clsx from "clsx";
import { useParams, useStore, useTestScenarios } from "../data/store";

const useStyles = createUseStyles((theme: any) => {
  return {
    root: { paddingTop: 16 },
    column: { "& > :not(:last-child)": { marginRight: 60 } },
    inline: {
      display: "flex",
      //flexWrap: "wrap",
      "& > :not(:last-child)": { marginRight: 30 },
    },
    // here we remove the space on top and bottom of the buttongroup separators
    // because it's ugly. Unfortunately because of a small bug in FormControl
    // we can't target the group but have to target the buttons instead...
    groupbutton: {
      "&:before": {
        top: "0 !important",
        bottom: "0 !important",
      },
    },
  };
}, "commonparams");

const CommonParams = () => {
  const classes = useStyles();
  const testScenarios = useTestScenarios();
  const params = useParams();
  const setParams = useStore((state) => state.setParams);
  const setCheckin = (value: string) => setParams({ checkin: value });
  const setImmigration = (value: string) => setParams({ immigration: value });
  return (
    <div className={classes.root}>
      <div className={classes.inline}>
        <FormControl label="Flight Time (h)" required>
          <Slider
            minValue={1}
            maxValue={12}
            step={1}
            marks
            defaultValue={[params.flight_time]}
            onChangeEnd={(value: any) => setParams({ flight_time: value[0] })}
          />
        </FormControl>
        <FormControl label="Check-in" required>
          <ButtonGroup size="small" aria-label="Check-in">
            <Button
              className={classes.groupbutton}
              pressed={params.checkin === "online"}
              onClick={() => setCheckin("online")}
            >
              online
            </Button>
            <Button
              className={classes.groupbutton}
              pressed={params.checkin === "kiosk"}
              onClick={() => setCheckin("kiosk")}
            >
              kiosk
            </Button>
            <Button
              className={classes.groupbutton}
              pressed={params.checkin === "counter"}
              onClick={() => setCheckin("counter")}
            >
              counter
            </Button>
          </ButtonGroup>
        </FormControl>
        <FormControl label="Immigration/Passport control" required>
          <ButtonGroup size="small" aria-label="Immigration/Passport control">
            <Button
              className={classes.groupbutton}
              pressed={params.immigration === "none"}
              onClick={() => setImmigration("none")}
            >
              none
            </Button>
            <Button
              className={classes.groupbutton}
              pressed={params.immigration === "kiosk"}
              onClick={() => setImmigration("kiosk")}
            >
              kiosk
            </Button>
            <Button
              className={classes.groupbutton}
              pressed={params.immigration === "counter"}
              onClick={() => setImmigration("counter")}
            >
              counter
            </Button>
          </ButtonGroup>
        </FormControl>
      </div>
      <div className={clsx(classes.inline)}>
        <FormControl label="Total Time in Terminal (h)" required>
          <Slider
            minValue={1}
            maxValue={5}
            step={1}
            marks
            defaultValue={[params.time_at_airport]}
            onChangeEnd={(value: any) =>
              setParams({ time_at_airport: value[0] })
            }
          />
        </FormControl>
        <FormControl label="Catering" required>
          <Toggle
            label="Yes"
            /*
            // @ts-ignore */
            size="small"
            checked={params.catering}
            onChange={(event) => setParams({ catering: event.target.checked })}
          />
        </FormControl>
        <FormControl label="Test Strategy" required>
          <Select
            size="small"
            onChange={(event: any) =>
              setParams({ test_scenario: event.target.value })
            }
          >
            {testScenarios.map((scenario: any) => (
              <option key={`scenario-${scenario.index}`} value={scenario.index}>
                {scenario.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default CommonParams;
