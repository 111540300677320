import React from "react";
import { createUseStyles } from "@airbus/components-react";
import uniqueId from "lodash.uniqueid";
import clsx from "clsx";
import { ColorScheme, useColorScheme } from "./colorSchemes";

const useStyles = createUseStyles(
  () => ({
    root: {
      display: "flex",
      flexOrientation: "column",
      justifyContent: "center",
    },
    color: {
      width: 16,
      height: 16,
      display: "inline-block",
      marginRight: 8,
    },
    label: {
      fontSize: 14,
      marginRight: 16,
    },
  }),
  "legend"
);

type LegendProps = {
  data: string[];
  className?: string;
  colors?: string[];
  colorScheme?: ColorScheme;
};

const Legend: React.FC<LegendProps> = ({
  className,
  data,
  colors: userProvidedColors,
  colorScheme = "grey" /* to implement */,
}) => {
  const classes = useStyles();
  const schemeColors = useColorScheme(colorScheme as ColorScheme, data.length);
  const colors = userProvidedColors || schemeColors;
  return (
    <div className={clsx(classes.root, className)}>
      {data.map((name, idx) => (
        <React.Fragment key={uniqueId("legend-")}>
          <span
            className={classes.color}
            style={{ background: colors[idx] }}
          ></span>
          <span className={classes.label}>{name}</span>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Legend;
