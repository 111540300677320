import { processInfectionProbabilities } from "./steps";

export const processScenarioTravelRisk = (
  scenario: any,
  dataByCountry: any,
  params: any
) => {
  return dataByCountry.map((row: any) => {
    const { country_name, hidden_contagious_rate, ...rest } = row;
    const { aircraft, terminal } = processTravelRisk(row, params);
    return {
      ...rest,
      country_name,
      travel_risk_pre_test: aircraft + terminal,
      hidden_contagious_rate_pre_test: hidden_contagious_rate,
      travel_risk: applyTestScenario(
        // the vaccination factor is applied twice: once to account for reduction in hidden contagious, and once to account for reduced transmission in infected pax
        applyVaccineImpact(
          applyVaccineImpact(aircraft + terminal, params),
          params
        ),
        scenario
      ),
      // why don't we differentiate between departure and arrival here?
      hidden_contagious_rate:
        applyVaccineImpact(hidden_contagious_rate, params) *
        // 100 * // for the graph, make hidden contagious a percentage
        (1 - scenario.test_eff_departure / 100) *
        (1 - scenario.test_eff_arrival / 100),
    };
  });
};

export const processTotalRiskByStep = (countryData: any, params: any) => {
  const { hidden_contagious_rate, collective_immunity, ground_disrespect } =
    countryData;
  const riskByStep = processInfectionProbabilities(
    hidden_contagious_rate,
    collective_immunity,
    ground_disrespect,
    params
  );
  return riskByStep.reduce(
    (acc, { name, values }) => ({ ...acc, [name]: values.P_total }),
    {}
  );
};

export const processTravelRisk = (countryData: any, params: any) => {
  const totals: any = processTotalRiskByStep(countryData, params);
  return {
    terminal:
      totals.BTE +
      totals.checkin +
      totals.security +
      totals.immigration +
      totals.gate +
      totals.boarding +
      2 * totals.bridge /*+
      totals.baggage*/,
    aircraft:
      totals.acdoors +
      totals.entering +
      totals.seated +
      totals.catering +
      totals.lavatories +
      totals.disembarking,
  };
};

export const applyTestScenario = (value: number, scenario: any) => {
  // DIFFERENTIATE TWO CASES, TEST APPLIED DIRECTLY ON ARRIVAL OR AFTER A QUANRANTINE
  if (scenario.apply_e2e_infected) {
    return (
      value *
      (1 - scenario.test_eff_departure / 100) *
      (1 - scenario.test_eff_arrival / 100)
    );
  }
  return value * (1 - scenario.test_eff_departure / 100);
};

export const applyVaccineImpact = (value: number, params: any) => {
  if (!params.all_pax_vaccinated) return value;
  return value * (1 - params.vaccine_infection_eff / 100); // was using vaccine_transmission_reduction before
};

export const processTrafficRisk = (
  dataByCountry: any,
  destinationCountryData: any,
  trafficDataByCountry: any,
  params: any
) => {
  const { population } = destinationCountryData;
  return dataByCountry.map((row: any) => {
    const { country_name, hidden_contagious_rate, travel_risk } = row;
    const trafficData = trafficDataByCountry.find(
      (r: any) => r.origin_country === country_name
    );
    const traffic_risk = trafficData
      ? (
          (((((hidden_contagious_rate + travel_risk) *
            Number(trafficData.traffic)) /
            365) *
            14 *
            params.traffic_percent) /
            100 /
            population) *
          100000
        ).toFixed(2)
      : 0;
    return { ...row, traffic_risk };
  });
};
