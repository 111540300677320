import { StepFunction } from "./types";

// boarding
export const step6: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  let P_total = 0,
    P_FF1 = 0,
    P_FF2 = 0,
    P_FC1 = 0,
    P_FF3_FF4 = 0,
    P_FF5 = 0,
    P_FC2 = 0;

  // probability that hands get infected at boarding call
  P_FF1 =
    hidden_contagious *
    params.hand_infection.not_disinfecting *
    params.hand_infection.touch_contactless *
    params.hand_infection.same_touch_dispenser *
    params.hand_infection.incorrect_usage;

  // probability to touch face
  P_FF2 =
    (1 - collective_immunity) *
    params.mask_proba.ratio_touch_mask *
    Math.min(
      1,
      params.time.time_boarding * params.mask_proba.rate_min_touch_face
    );

  // probability of FC1
  P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

  // probability that there is a contagious person nearby
  P_FF3_FF4 = hidden_contagious;

  // probability that not protected against contamination
  // SCENARIO 1 - person closeby sneezes and does not respect social distance in queue
  const P_FF5_s1 =
    (1 - collective_immunity) *
    ground_disrespect *
    params.mask_proba.eff_reduction1 *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) **
        params.time.time_boarding_queue);

  // SCENARIO 2 - during passport control
  const P_FF5_s2 =
    (1 - collective_immunity) *
    ground_disrespect *
    params.mask_proba.eff_reduction1 *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) **
        params.time.timeat_boarding);

  // SCENARIO 3 - infection from the staff
  const P_FF5_s3 =
    (1 - collective_immunity) *
    params.mask_proba.staff_mask_defect *
    params.mask_proba.eff_reduction1 *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) **
        params.time.timeat_boarding);

  P_FF5 = P_FF5_s1 + P_FF5_s2 + P_FF5_s3;

  P_FC2 = P_FF3_FF4 * P_FF5;

  // calculate total risk
  P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
