import { StepFunction } from "./types";

// security
export const step3: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  let P_total = 0,
    P_FF1 = 0,
    P_FF2 = 0,
    P_FC1 = 0,
    P_FF3_FF4 = 0,
    P_FF5 = 0,
    P_FC2 = 0;

  //probability that hands get infected at check-in counter
  //SCENARIO 1 - through hand dispenser
  let P_FF1_s1 =
    hidden_contagious *
    params.hand_infection.not_disinfecting *
    params.hand_infection.same_touch_dispenser *
    params.hand_infection.incorrect_usage;

  //SCENARIO 2 - through trays
  let P_FF1_s2 =
    (1 -
      (1 - hidden_contagious * params.hand_infection.not_disinfecting) **
        params.security_check.number_trays) *
    (1 / params.security_check.number_trays) *
    params.hand_infection.poor_surface_cleaning;

  P_FF1 = P_FF1_s1 + P_FF1_s2;

  // probability to touch face
  P_FF2 =
    (1 - collective_immunity) *
    params.mask_proba.ratio_touch_mask *
    Math.min(
      1,
      params.time.time_security * params.mask_proba.rate_min_touch_face
    );

  //probability of FC1
  P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

  // probability that there is a contagious person nearby
  P_FF3_FF4 = hidden_contagious;

  // probability that not protected against contamination
  P_FF5 =
    (1 - collective_immunity) *
    ground_disrespect *
    params.mask_proba.eff_reduction1 *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) **
        params.time.time_security_queue);

  P_FC2 = P_FF3_FF4 * P_FF5;

  // calculate total risk
  P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
