import React from "react";
import clsx from "clsx";
import { useSlider, useSliderThumb } from "@react-aria/slider";
import { useSliderState } from "@react-stately/slider";
import { useFocusRing } from "@react-aria/focus";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { mergeProps } from "@react-aria/utils";
import { useNumberFormatter } from "@react-aria/i18n";
import { createUseStyles } from "@airbus/components-react";

const useStyles = createUseStyles((theme: any) => {
  const { color, elevation } = theme;
  return {
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: 300,
      touchAction: "none",
      marginBottom: 20,
    },
    track: {
      position: "relative",
      height: 24,
      width: "100%",
    },
    trackLine: {
      position: "absolute",
      backgroundColor: color.background.interactive.primary.small,
      borderRadius: 4,
      height: 8,
      top: 4,
      width: "100%",
    },
    marks: {
      position: "relative",
      width: "100%",
      fontSize: 12,
    },
    mark: {
      position: "absolute",
      transform: "translateX(-50%)",
    },
    markSelected: {
      fontWeight: "bold",
    },
    thumb: {
      position: "absolute",
      top: 0,
      transform: "translateX(-50%)",
      cursor: "pointer",
    },
    thumbKnob: {
      width: 16,
      height: 16,
      borderRadius: "50%",
      backgroundColor: color.background.interactive.primary.small,
      border: "2px solid white",
      boxShadow: elevation.level2,
    },
    thumbFocused: {
      border: "2px solid gray",
    },
    thumbDragging: {
      backgroundColor: color.background.interactive.primary.default,
    },
  };
}, "slider");

function Slider(props: any) {
  let classes = useStyles();
  let trackRef = React.useRef(null);
  let numberFormatter = useNumberFormatter(props.formatOptions);
  let state = useSliderState({ ...props, numberFormatter });
  let { groupProps, trackProps } = useSlider(props, state, trackRef);

  const { minValue, maxValue, step } = props;
  const marks = [];
  if (props.marks) {
    for (let i = minValue; i <= maxValue; i = i + step) {
      marks.push(i);
    }
  }

  return (
    <div {...groupProps} className={classes.root}>
      {/* Create a flex container for the label and output element. */}
      {/* <div style={{ display: "flex", alignSelf: "stretch" }}>
        {props.label && <label {...labelProps}>{props.label}</label>}
        <output {...outputProps} style={{ flex: "1 0 auto", textAlign: "end" }}>
          {state.getThumbValueLabel(0)}
        </output>
    </div> */}
      {/* The track element holds the visible track line and the thumb. */}
      <div {...trackProps} ref={trackRef} className={classes.track}>
        <div className={classes.trackLine} />
        <Thumb index={0} state={state} trackRef={trackRef} />
      </div>

      {marks.length > 0 && (
        <div className={classes.marks}>
          {marks.map((m, idx) => (
            <span
              key={m}
              className={clsx(
                classes.mark,
                m === state.getThumbValue(0) && classes.markSelected
              )}
              style={{
                left: `${idx * (100 / (marks.length - 1))}%`,
              }}
            >
              {m}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

function Thumb(props: any) {
  let classes = useStyles();
  let { state, trackRef, index } = props;
  let inputRef = React.useRef(null);
  let { thumbProps, inputProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state
  );

  let { focusProps, isFocusVisible } = useFocusRing();
  return (
    <div
      className={classes.thumb}
      style={{
        left: `${state.getThumbPercent(index) * 100}%`,
      }}
    >
      <div
        {...thumbProps}
        className={clsx(
          classes.thumbKnob,
          isFocusVisible && classes.thumbFocused,
          state.isThumbDragging(index) && classes.thumbDragging
        )}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </div>
    </div>
  );
}

export default Slider;
