import React from "react";
import uniqueId from "lodash.uniqueid";
import { FormControl, Inline, Input, Select } from "@airbus/components-react";
import MetricCard from "./ui/MetricCard";
import {
  useCountryNames,
  useCountryStats,
  useParams,
  useRegions,
  useStore,
  isRegion,
  computeVaccineWeightedMedian,
  useSelectedCountriesData,
} from "../data/store";

const ExplorationModeParams = () => {
  const params = useParams();
  const setParams = useStore((state) => state.setParams);
  const countries = useCountryNames();
  const regions = useRegions();
  const destinationCountryData = useCountryStats(params.destination_country);
  const selectedCountriesData = useSelectedCountriesData();

  return (
    <Inline spacing="2-x">
      <FormControl label="Departure Region" required>
        <Select
          size="small"
          value={params.departure_region}
          onChange={(event: any) =>
            setParams({ departure_region: event.target.value })
          }
        >
          {regions.map((name: string) => (
            <option key={`region-${uniqueId()}`} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl label="Destination Country (reference)" required>
        <Select
          size="small"
          value={params.destination_country}
          onChange={(event: any) =>
            setParams({ destination_country: event.target.value })
          }
        >
          {countries.map((name: string) => (
            <option key={`country-${uniqueId()}`} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl label="% Air Traffic compared to 2019" required>
        <Input
          size="small"
          defaultValue={params.traffic_percent}
          onBlur={(event: any) =>
            setParams({ traffic_percent: event.target.value })
          }
        />
      </FormControl>
      <MetricCard
        small
        title="% Vaccinated Departure"
        number={
          selectedCountriesData.length !== 0
            ? computeVaccineWeightedMedian(selectedCountriesData).toFixed(0)
            : "-"
        }
      />
      <MetricCard
        small
        title="14 day Incidence Destination"
        number={destinationCountryData.prevalence.toFixed(0)}
      />
      <MetricCard
        small
        title="Transmission Rate Destination"
        number={
          isRegion(destinationCountryData)
            ? "-"
            : destinationCountryData.r0_latest.toFixed(2)
        }
        trend={destinationCountryData.r0_percent_change}
      />
    </Inline>
  );
};

export default ExplorationModeParams;
