import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
} from "@airbus/components-react";

const Login = ({ onClick }: any) => {
  return (
    <Dialog open={true} disableBackdrop={true}>
      <DialogHeader>Authentication required</DialogHeader>
      <DialogContent />
      <DialogActions>
        <Button onClick={onClick} variant="primary">
          Sign up with OneLogin
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Login;
