import React from "react";
import { pie as createPie, arc as createArc, scaleOrdinal } from "./utils";
import { useColorScheme, ColorSchemeName } from "./colorSchemes";
import { Tooltip } from ".";

type PieChartProps = {
  data: number[] | { name: string; value: any }[];
  /* adds a border to the slices */
  stroke?: string;
  /* the width of the border */
  strokeWidth?: number;
  /* defines the number of pixels between the center of the chart and the inner edge of a donut */
  innerRadius?: number;
  /* defines the amount of separation between adjacent slices ; keep it < 0.1 for best results */
  padAngle?: number;
  /* if true, labels will be added to the chart */
  labels?: boolean;
  /* the color scheme */
  colorScheme?: ColorSchemeName;
  /* if true, tooltips will be displayed on hover */
  tooltip?: boolean;
};

const TOOLTIP_HEIGHT = 32;
const TOOLTIP_MARGIN_FROM_CURSOR = 8;

const getEventHandlers = (tooltip: any, datum: any) => ({
  onMouseOver: () => {
    tooltip.current.firstChild.textContent = datum;
    tooltip.current.style.opacity = 1;
  },
  onMouseLeave: () => {
    tooltip.current.style.opacity = 0;
  },
  onMouseMove: (event: any) => {
    tooltip.current.style.top = `${
      event.pageY - TOOLTIP_HEIGHT - TOOLTIP_MARGIN_FROM_CURSOR
    }px`;
    tooltip.current.style.left = `${
      event.pageX - TOOLTIP_MARGIN_FROM_CURSOR
    }px`;
  },
});

export const PieChart = ({
  data,
  innerRadius = 0,
  padAngle = 0,
  labels = false,
  colorScheme: colorSchemeName = "cool",
  tooltip = true,
  ...rest
}: PieChartProps) => {
  const tooltipRef = React.useRef<HTMLElement>(null);

  const colorScheme = useColorScheme(colorSchemeName, data.length);

  const height = 250;
  const width = 250;
  const margin = 30;

  const outerRadius = Math.min(width, height) / 2 - margin;

  const nameAccessor = (d: any) =>
    typeof d === "number" ? d.toString() : d.name;
  const valueAccessor = (d: any) => (typeof d === "number" ? d : d.value);

  const pie = createPie().sort(null).value(valueAccessor).padAngle(padAngle);
  const arcs = pie(data as any[]);

  const arc = createArc().innerRadius(innerRadius).outerRadius(outerRadius);

  //const radius = (Math.min(width, height) / 2) * 0.8;
  const labelRadius = Math.min(width, height) / 2 - margin / 2;
  const arcLabel = createArc()
    .innerRadius(labelRadius)
    .outerRadius(labelRadius);

  const color = scaleOrdinal()
    .domain((data as any[]).map(nameAccessor))
    .range(colorScheme);

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0,0,${width},${height}`}
      style={{ margin: "0 auto", width: "100%" }}
    >
      <g
        transform={"translate(" + width / 2 + "," + height / 2 + ")"}
        {...rest}
      >
        {arcs.map((a: any) => (
          <path
            key={a.index}
            fill={color(nameAccessor(a.data)) as string}
            d={arc(a) as string}
            {...getEventHandlers(tooltipRef, valueAccessor(a.data))}
          ></path>
        ))}
      </g>
      {labels && (
        <g
          transform={"translate(" + width / 2 + "," + height / 2 + ")"}
          {...{
            fontFamily: "inherit",
            fontSize: 12,
            textAnchor: "middle",
            color: "white",
          }}
        >
          {arcs.map((a: any) => (
            <text
              key={a.index}
              transform={`translate(${arcLabel.centroid(a)})`}
            >
              <tspan>{valueAccessor(a.data)}</tspan>
            </text>
          ))}
        </g>
      )}
      {tooltip && <Tooltip ref={tooltipRef} />}
    </svg>
  );
};
