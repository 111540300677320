import React from "react";
import { ThemeProvider } from "@airbus/components-react";
import "typeface-roboto";
import Amplify, { Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import awsconfig from "../config";
import Dashboard from "./Dashboard";
import Login from "./Login";

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event, data);
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          break;
        case "customOAuthState":
          console.log("customOAuthState", data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => console.log("Not signed in"));
  }, []);

  if (user === null) {
    return (
      <Login
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Cognito,
          })
        }
      />
    );
  }
  return (
    <ThemeProvider brand="airbus">
      <Dashboard />
    </ThemeProvider>
  );
}

export default App;
