import tokens from "@airbus/design-tokens";
import { quantize, interpolateSpectral } from "./utils";

export type ColorSchemeName =
  | "chromatic"
  | "cool"
  | "qualitative"
  | "highlight"
  | "red"
  | "green"
  | "blue";

export type ColorScheme = ColorSchemeName | string[];

const createSchemeFromPalette = (color: string) => {
  return [10, 20, 30, 40, 50, 60, 70, 80, 90]
    .map((shade: number) => tokens.airbus.color.palette[color][shade])
    .reverse();
};

export const useColorSchemes = () => {
  return {
    cool: [
      "#255fcc", // primaryblue 50
      "#2e9fe0", // secondaryblue 40
      "#9cca32", // limegreen 50
      "#e7e632", // limeyellow 50
      "#e93255", // red 50
      "#b746a6", // purple 50
    ],
    qualitative: [
      // "#b746a6", // purple 50
      // "#03b075", // green 50
      "#e93255", // red 50
      "#255fcc", // primaryblue 50
      "#ff9232", // orange 50
    ],
    highlight: ["#2e9fe0", "#c5d3db"],
    red: createSchemeFromPalette("pink"),
    green: createSchemeFromPalette("limegreen"),
    blue: createSchemeFromPalette("secondaryblue"),
    chromatic: (length: number) =>
      quantize((t) => interpolateSpectral(t * 0.8 + 0.1), length).reverse(),
  };
};

export const useColorScheme = (
  schemeName: ColorScheme,
  dataLength = 10
): string[] => {
  const colorSchemes = useColorSchemes();
  if (Array.isArray(schemeName)) {
    return schemeName;
  }
  const scheme = colorSchemes[schemeName];
  return typeof scheme === "function"
    ? (scheme as Function)(dataLength)
    : scheme;
};
