import React, { ReactNode } from "react";
import { createScale, inferScalesFromChildren, useChartScales } from "./Chart";
import { ColorSchemeName, useColorScheme } from "./colorSchemes";
import { max, min } from "./utils";

type GroupProps = {
  offset?: number;
  /* the color scheme */
  colorScheme?: ColorSchemeName;
  children: ReactNode;
};

export const Group = ({
  colorScheme: colorSchemeName = "cool",
  children,
}: GroupProps) => {
  const { x: xScale } = useChartScales();
  const colorScheme = useColorScheme(
    colorSchemeName,
    React.Children.count(children)
  );
  const domain = React.Children.map(children, (_, idx) => `${idx}`) as [];
  const colorScale = createScale(
    {
      type: "ordinal",
      domain,
    },
    colorScheme
  );
  const charts = React.useMemo(() => {
    if (!children) return;
    const offsetScale = createScale(
      {
        type: "band",
        domain,
        padding: 0.5,
      },
      [0, xScale.bandwidth()]
    );
    return React.Children.map(children, (child, idx) => {
      if (!React.isValidElement(child)) {
        return null;
      }
      return React.cloneElement<any>(child, {
        offset: offsetScale(`${idx}` as any),
        width: (offsetScale as any).bandwidth(),
        color: colorScale(`${idx}` as any),
        ...child.props,
      });
    });
  }, [children, xScale, domain, colorScale]);

  return <>{charts}</>;
};
Group.getScaleDefs = ({ children }: GroupProps) => {
  const childrenScaleDefs = inferScalesFromChildren(children);
  // we should check if all scales are compatible, ie same type
  return {
    x: {
      ...childrenScaleDefs.x[0],
      padding: 0.1,
    },
    y: {
      ...childrenScaleDefs.y[0],
      domain: [
        min(childrenScaleDefs.y, (def) => def.domain[0]),
        max(childrenScaleDefs.y, (def) => def.domain[1]),
      ],
    },
  };
};
