import { StepFunction } from "./types";

// BTE
export const step1: StepFunction = (
  hidden_contagious,
  collective_immunity,
  ground_disrespect,
  params
) => {
  // probability that hands get infected at APTE
  const P_FF1 =
    hidden_contagious *
    params.hand_infection.touch_contactless *
    params.hand_infection.incorrect_usage *
    params.hand_infection.same_touch_dispenser;

  // probability to touch face
  const P_FF2 =
    (1 - collective_immunity) *
    params.mask_proba.ratio_touch_mask *
    Math.min(1, params.time.time_BTE * params.mask_proba.rate_min_touch_face);

  // probability of FC1
  const P_FC1 = P_FF1 * P_FF2 * params.measures.fomites_factor;

  // probability that there is a contagious person nearby
  const P_FF3_FF4 = hidden_contagious;

  // probability that not protected against contamination
  const P_FF5 =
    (1 - collective_immunity) *
    ground_disrespect *
    params.mask_proba.eff_reduction1 *
    (1 -
      (1 - params.mask_proba.rate_coughing_sneezing) **
        params.time.time_BTE_queue);

  // probability of FC2
  const P_FC2 = P_FF3_FF4 * P_FF5;

  // calculate total risk
  const P_total = P_FC1 + P_FC2;

  return {
    P_total,
    P_FF1,
    P_FF2,
    P_FC1,
    P_FF3_FF4,
    P_FF5,
    P_FC2,
  };
};
