import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  Inline,
  Stack,
} from "@airbus/components-react";
import {
  useCountryStats,
  useParams,
  useStatsQuery,
  useTestScenarios,
  useTrafficData,
} from "../data/store";
import { processScenarioTravelRisk, processTrafficRisk } from "../data";
import ChartCard from "./ui/ChartCard";
import {
  Legend,
  XAxis,
  YAxis,
  PieChart,
  sum,
  Chart,
  Bar,
  Tooltip,
} from "./charting";

const TestStrategyComparison = (props: any) => {
  const params = useParams();
  const [selected, setSelected] = React.useState(["0", "1", "2"]);
  const scenarios = useTestScenarios();
  const selectedScenarios = useTestScenarios(selected);
  const departureRegionDataByCountry = useStatsQuery(
    (r: any) => r.region === params.departure_region
  );
  const destinationCountryData = useCountryStats(params.destination_country);
  const trafficDataByCountry = useTrafficData(
    props.selectedCountries,
    params.destination_country
  );
  const data = selectedScenarios.map((scenario: any) => {
    const travelRiskByCountry = processScenarioTravelRisk(
      scenario,
      departureRegionDataByCountry,
      params
    );
    const trafficRiskByCountry = processTrafficRisk(
      travelRiskByCountry,
      destinationCountryData,
      trafficDataByCountry,
      params
    );
    return {
      scenario_name: scenario.name,
      risk: sum(trafficRiskByCountry, (row: any) => row.traffic_risk),
      incidence: destinationCountryData.prevalence.toFixed(0),
    };
  });

  return (
    <>
      <ChartCard
        title="14-day Imported Cases (using traffic data) - Test Strategy Comparison"
        explainer="Takes into account the air traffic data over two weeks from selected departure countries to destination country"
      >
        <Inline align="center">
          {data.map(({ scenario_name, risk, incidence }: any) => (
            <Stack align="center" key={scenario_name}>
              <h5>{scenario_name}</h5>
              <PieChart
                key={scenario_name}
                colorScheme="highlight"
                data={[
                  { name: "14 day imported cases", value: risk },
                  { name: "14 day destination incidence", value: incidence },
                ]}
              />
            </Stack>
          ))}
        </Inline>
        <Legend
          data={["14 day imported cases", "14 day destination incidence"]}
          colorScheme="highlight"
        />
        <Inline alignY="start" spacing="1-x" style={{ marginTop: 20 }}>
          <Chart width={600}>
            <XAxis label="Strategy" />
            <YAxis label="Imported cases" grid />
            <Bar x="scenario_name" y="risk" data={data} tooltip={<Tooltip />} />
          </Chart>
          <CheckboxGroup
            name="test-scenarios"
            value={selected}
            onChange={(e, value) => setSelected(value)}
          >
            {scenarios.map((scenario) => (
              <Checkbox
                key={`cbx-ts-${scenario.index}`}
                value={scenario.index}
                label={scenario.name}
              />
            ))}
          </CheckboxGroup>
        </Inline>
      </ChartCard>
    </>
  );
};

export default TestStrategyComparison;
