import { step1 } from "./1_BTE";
import { step2 } from "./2_checkin";
import { step3 } from "./3_security";
import { step4 } from "./4_immigration";
import { step5 } from "./5_gate";
import { step6 } from "./6_boarding";
import { step7 } from "./7_bridge";
import { step8 } from "./8_acdoors";
import { step9 } from "./9_entering";
import { step10 } from "./10_seated";
import { step11 } from "./11_catering";
import { step12 } from "./12_lavatories";
import { step13 } from "./13_disembarking";
import { step14 } from "./14_baggage";

export const steps = [
  { name: "BTE", fn: step1 },
  { name: "checkin", fn: step2 },
  { name: "security", fn: step3 },
  { name: "immigration", fn: step4 },
  { name: "gate", fn: step5 },
  { name: "boarding", fn: step6 },
  { name: "bridge", fn: step7 },
  { name: "acdoors", fn: step8 },
  { name: "entering", fn: step9 },
  { name: "seated", fn: step10 },
  { name: "catering", fn: step11 },
  { name: "lavatories", fn: step12 },
  { name: "disembarking", fn: step13 },
  { name: "baggage", fn: step14 },
];

export const processInfectionProbabilities = (
  hiddenContagious: number,
  collectiveImmunityDatum: number,
  groundDirespect: number,
  params: any
) => {
  const collectiveImmunity =
    params.immunity === true
      ? collectiveImmunityDatum + params.vaccination / 100
      : 0;
  return steps.map(({ name, fn }) => ({
    name,
    values: fn(hiddenContagious, collectiveImmunity, groundDirespect, params),
  }));
};
