import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  createUseStyles,
  IconButton,
} from "@airbus/components-react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Legend } from "../charting";

const useStyles = createUseStyles((theme: any) => {
  const { color, font } = theme;
  return {
    root: {
      maxWidth: "initial !important",
    },
    explainer: {
      color: color.text.secondary,
      fontFamily: "inherit",
      fontSize: font.size.xsmall,
      lineHeight: font.lineheight.xsmall,
      margin: "0 0 12px 0 !important",
    },
    legend: {
      backgroundColor: color.background.neutral.lighter,
      padding: "8px 18px",
    },
  };
}, "chart");

const ChartCard = ({ title, explainer, children, legend }: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const legendNode =
    legend && Array.isArray(legend) ? (
      <Legend
        data={legend.map((r: any) => r.name)}
        colorScheme={legend.map((r: any) => r.color)}
        className={classes.legend}
      />
    ) : (
      legend
    );
  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
        action={
          <IconButton variant="ghost" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        }
      />

      <CardContent>
        {open && (
          <>
            <p className={classes.explainer}>{explainer}</p>
            {children}
          </>
        )}
      </CardContent>

      {open && legend && <div className={classes.legend}>{legendNode}</div>}
    </Card>
  );
};

export default ChartCard;
