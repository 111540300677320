import { Stack } from "@airbus/components-react";
import React from "react";
import { useCountryStats, useParams, useStatsQuery } from "../data/store";
import { Bar, Chart, Tooltip, XAxis, YAxis } from "./charting";
import ChartCard from "./ui/ChartCard";

const FourteenDayRates = () => {
  const params = useParams();
  const { region } = useCountryStats(params.departure_country);
  const data = useStatsQuery((r: any) => r.region === region).map(
    (row: any) => ({
      country_name: row.country_name,
      prevalence: Number(row.prevalence.toFixed(0)),
      hidden_contagious_rate: Number(
        (row.hidden_contagious_rate * 100).toFixed(2)
      ),
    })
  );
  return (
    <Stack spacing="2-x">
      <ChartCard
        title="14 Day Incidence Rates"
        explainer="For the departure region"
      >
        <Chart margin={{ top: 10, right: 10, bottom: 140, left: 80 }}>
          <XAxis label="Country" rotateTickLabels />
          <YAxis label="Cases per 100000" grid />
          <Bar
            x="country_name"
            y="prevalence"
            data={data}
            tooltip={<Tooltip />}
          />
        </Chart>
      </ChartCard>
      <ChartCard
        title="14 Day Hidden Contagious Rates"
        explainer="For the departure region"
      >
        <Chart margin={{ top: 10, right: 10, bottom: 140, left: 80 }}>
          <XAxis label="Country" rotateTickLabels />
          <YAxis label="Rate %" grid />
          <Bar
            x="country_name"
            y="hidden_contagious_rate"
            data={data}
            tooltip={<Tooltip />}
          />
        </Chart>
      </ChartCard>
    </Stack>
  );
};

export default FourteenDayRates;
