import React from "react";
import { createUseStyles, Input, Toggle } from "@airbus/components-react";
import { useParams, useStore } from "../data/store";
import clsx from "clsx";

const useStyles = createUseStyles((theme: any) => {
  const { font, color } = theme;
  return {
    inline: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": { marginRight: 20, marginBottom: 10 },
    },
    control: {
      display: "flex",
      alignItems: "center",
      width: 180,
      minHeight: 40,
    },
    disabled: {
      opacity: 0.5,
    },
    label: {
      flexGrow: 4,
      fontFamily: "inherit",
      fontSize: font.size.xsmall,
      fontWeight: font.weight.medium,
      lineHeight: font.lineheight.small,
      color: color.text.secondary,
      marginRight: 10,
    },
    input: {
      width: "50px !important",
    },
  };
}, "advancedparams");

const AdvancedParams = () => {
  const classes = useStyles();
  const params = useParams();
  const setParams = useStore((state) => state.setParams);
  const saveOnBlur = (key: string) => (event: any) =>
    setParams({ [key]: event.target.value });

  const InlineFormControl = ({
    id,
    label,
    disabled = false,
  }: {
    id: string;
    label: string;
    disabled?: boolean;
  }) => (
    <div className={clsx(classes.control, disabled && classes.disabled)}>
      <label className={classes.label} htmlFor={id}>
        {label}
      </label>
      <Input
        className={classes.input}
        id={id}
        defaultValue={params[id]}
        disabled={disabled}
        size="small"
        onBlur={saveOnBlur(id)}
      />
    </div>
  );

  const InlineToggle = ({ id, label }: { id: string; label: string }) => (
    <div className={classes.control}>
      <Toggle
        /*
        // @ts-ignore */
        size="small"
        id={id}
        checked={params[id]}
        onChange={(event) => setParams({ [id]: event.target.checked })}
      />
      <label className={classes.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );

  const controls = [
    { id: "num_pax", label: "Number of pax" },
    { id: "pax_boarding", label: "# of pax in boarding group" },
    { id: "seats_gate", label: "Seats at gate" },
    { id: "seats_blocked", label: "% Seats blocked at gate" },
    { id: "flights_using_gate", label: "# of flights using same gate" },
    { id: "fomites_factor", label: "Fomites factor" },
    { id: "air_con_eff", label: "Air Cond Efficiency Factor" },
    { id: "sneezing_rate", label: "Average sneezing rate (per day)" },
    { id: "mask_eff_red1", label: "% Mask efficiency" },
    { id: "time_eating", label: "Time eating (catering)" },
    {
      id: "flights_s_cleaning",
      label: "Number of flights since A/C cleaning",
    },
    { id: "pax_eating", label: "% of pax eating at the same time" },
  ];
  return (
    <>
      <div className={classes.inline}>
        {controls.map((props) => (
          <InlineFormControl key={props.id} {...props} />
        ))}
      </div>
      <div className={classes.inline}>
        {/* <InlineToggle id="immunity" label="Consider immunity" /> 
        <InlineFormControl
          disabled={!params.immunity}
          id="vaccination"
          label="% population vaccinated"
        /> */}
        <InlineToggle id="all_pax_vaccinated" label="All pax vaccinated" />
        <InlineFormControl
          id="vaccine_infection_eff"
          label="% avg eff at preventing infection"
        />
        <InlineFormControl
          id="vaccine_disease_eff"
          label="% avg eff at preventing disease"
        />
      </div>
      <div className={classes.inline}>
        <InlineToggle id="manual_stats" label="Manual Covid inputs" />
        <InlineFormControl
          disabled={!params.manual_stats}
          id="incidence"
          label="14 day cases"
        />
        <InlineFormControl
          disabled={!params.manual_stats}
          id="leth_rate"
          label="Lethality Rate %"
        />
        <InlineFormControl
          disabled={!params.manual_stats}
          id="population"
          label="Population (M)"
        />
      </div>
    </>
  );
};

export default AdvancedParams;
