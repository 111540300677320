import React from "react";
import { Tooltip as Styles } from "@airbus/elements";
import { createUseStyles } from "@airbus/components-react";
import ReactDOM from "react-dom";

const useStyles = createUseStyles(Styles, "tooltip");

type TooltipProps = {
  text?: string | number;
  x?: number;
  y?: number;
};

export const Tooltip = React.forwardRef((props: TooltipProps, ref: any) => {
  const classes = useStyles();
  const { text = "-" } = props;
  const mountNode = document.body;
  return ReactDOM.createPortal(
    <div
      className={classes.root}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
        opacity: 0,
      }}
      ref={ref}
    >
      {text}
    </div>,
    mountNode
  );
});
