import create from "zustand";
import { useCallback } from "react";
import { csv as importCsv, json as importJson } from "d3-fetch";
import { default as processParams, defaultParams } from "./params";

const selectParams = (state: State) => state.params;
const selectSelectedCountries = (state: State) => state.selectedCountries;
const selectCountryNames = (state: State) =>
  state.statsPerCountry
    .map((r) => r.country_name)
    .sort((a: any, b: any) => a.localeCompare(b));
const selectRegionCountryNames = (region: string, state: State | any) =>
  state.statsPerCountry
    .filter((r: any) => r.region === region)
    .map((r: any) => r.country_name)
    .sort((a: any, b: any) => a.localeCompare(b));
const selectRegions = (state: State) => {
  const regions: string[] = [];
  state.statsPerCountry.forEach((r) => {
    if (
      r.region !== "" &&
      r.region !== "N/A" &&
      regions.indexOf(r.region) === -1
    ) {
      regions.push(r.region);
    }
  });
  return regions;
};
const selectCountryNamesByRegion = (state: State) =>
  selectRegions(state).map((region) => ({
    region,
    countries: selectRegionCountryNames(region, state),
  }));
export const isRegion = (stats: any) =>
  stats.region === "N/A" && stats.country_name !== "World";
export const useParams = () => useStore(selectParams);
export const useSelectedCountries = () => useStore(selectSelectedCountries);
export const useSelectedCountriesData = () => {
  const selectedCountries = useSelectedCountries();
  return useStatsQuery(
    (r: any) => selectedCountries.indexOf(r.country_name) !== -1
  );
};
export const useCountryNames = () => useStore(selectCountryNames);
export const useCountryNamesByRegion = () =>
  useStore(selectCountryNamesByRegion);
export const useRegionCountryNames = (region: string) =>
  useStore(
    useCallback((state) => selectRegionCountryNames(region, state), [region])
  );
export const useRegions = () => useStore(selectRegions);
export const useCountryStats = (name: string) =>
  useStore(
    useCallback(
      (state) => state.statsPerCountry.find((r) => r.country_name === name),
      [name]
    )
  );
export const useStatsQuery = (findFn: any) =>
  useStore(
    useCallback(
      (state) =>
        state.statsPerCountry
          .filter(findFn)
          .sort((a: any, b: any) =>
            a.country_name.localeCompare(b.country_name)
          ),
      [findFn]
    )
  );
export const useTestScenario = (idx: number) =>
  useStore(useCallback((state) => state.testScenarios[idx], [idx]));
export const useTestScenarios = (indexes?: string[]) =>
  useStore(
    useCallback(
      (state) =>
        indexes
          ? state.testScenarios.filter((r) => indexes.indexOf(r.index) !== -1)
          : state.testScenarios,
      [indexes]
    )
  );

export const useTrafficData = (
  departureCountries: string[],
  destinationCountry: string
) =>
  useStore(
    useCallback(
      (state) =>
        state.worldTraffic.filter(
          (r: any) =>
            departureCountries.indexOf(r.origin_country) !== -1 &&
            r.dest_country === destinationCountry
        ),
      [departureCountries, destinationCountry]
    )
  );

type State = {
  loading: boolean;
  params: any;
  statsPerCountry: any[];
  worldTraffic: any[];
  testScenarios: any[];
  selectedCountries: string[];
  setParams: (params: any) => void;
  selectCountries: (countries: string[]) => void;
  fetchData: () => void;
};

export const useStore = create<State>((set) => ({
  loading: true,
  params: {},
  statsPerCountry: [],
  worldTraffic: [],
  testScenarios: [],
  selectedCountries: [],
  setParams: (params: any) =>
    set((state) => ({
      params: processParams({ ...state.params, ...params }),
      selectedCountries:
        params.departure_region &&
        params.departure_region !== state.params.departure_region
          ? selectRegionCountryNames(params.departure_region, state)
          : state.selectedCountries,
    })),
  selectCountries: (countries: string[]) =>
    set({ selectedCountries: countries }),
  fetchData: async () => {
    const datasets = await loadDatasets();
    const params = {
      ...processParams(defaultParams),
      // TODO: we inject this param because of a strange dep to q_data within step 9
      world_hidden_contagious_rate: datasets.statsPerCountry.find(
        (stats: any) => stats.country_name === "World"
      )?.hidden_contagious_rate,
    };
    const selectedCountries = selectRegionCountryNames(
      params.departure_region,
      datasets
    );
    set({ loading: false, params, selectedCountries, ...datasets });
  },
}));

const processFullyVaccinatedRatio = (
  vaccinations: any,
  country: string,
  population: number
) => {
  const country_data = vaccinations.find(
    (datum: any) => datum.country === country
  );
  if (!country_data) {
    // console.log(`Cannot find vaccine data for ${country}`);
    return 0;
  }
  const last_data = country_data.data
    .reverse()
    .find((datum: any) => datum.people_fully_vaccinated !== undefined);
  if (!last_data) {
    console.log(`Cannot find fully vaccinated data for ${country}`);
    return 0;
  }
  return (last_data.people_fully_vaccinated / population) * 100;
};

const processVaccineUsData = (data: any) => {
  const processed: any = {};
  for (const datum of data) {
    const state = `USA: ${datum.location}`;
    if (!processed[state]) {
      processed[state] = {
        country: state,
        data: [],
      };
    }
    processed[state].data.push(datum);
  }
  const arr = [];
  for (const state in processed) {
    arr.push(processed[state]);
  }
  return arr;
};

const processVaccineRegionsData = (data: any) =>
  data.map((row: any) => {
    if (row.region !== "N/A" || row.vaccinated_population !== 0) return row;
    const countries = data.filter((r: any) => r.region === row.country_name);
    // console.log(
    //   `Processing vaccinated data for region ${row.country_name}`,
    //   countries,
    //   computeVaccineWeightedMedian(countries)
    // );
    return {
      ...row,
      vaccinated_population: computeVaccineWeightedMedian(countries),
    };
  });

export const computeVaccineWeightedMedian = (countries: any) =>
  countries.reduce(
    (acc: number, { vaccinated_population, population }: any) =>
      acc + vaccinated_population * population,
    0
  ) /
  countries.reduce((acc: number, { population }: any) => acc + population, 0);

const normalizeCountryName = (country: string) => {
  switch (country) {
    case "United States":
      return "USA";
    case "United Kingdom":
      return "UK";
    default:
      return country;
  }
};

const loadDatasets = async () => {
  const vaccinations_world = (await importJson(
    "https://raw.githubusercontent.com/owid/covid-19-data/master/public/data/vaccinations/vaccinations.json"
  )) as [];
  const vaccinations_usa = await importCsv(
    "https://raw.githubusercontent.com/owid/covid-19-data/master/public/data/vaccinations/us_state_vaccinations.csv"
  );
  const vaccinations = [
    ...vaccinations_world.map(({ country, ...rest }: any) => ({
      country: normalizeCountryName(country),
      ...rest,
    })),
    ...processVaccineUsData(vaccinations_usa),
  ];

  const statsPerCountry = processVaccineRegionsData(
    (await importCsv("./data/data.csv")).map(
      ({
        country_name,
        Population,
        Region,
        hidden_contagious_rate,
        collective_immunity,
        prevalence,
        Ground_Disrespect,
        r0_latest,
        r0_percent_change,
        ...rest
      }: any) => ({
        ...rest,
        country_name,
        population: Number(Population),
        region: Region,
        hidden_contagious_rate: Number(hidden_contagious_rate),
        vaccinated_population: processFullyVaccinatedRatio(
          vaccinations,
          country_name,
          Number(Population)
        ),
        collective_immunity: Number(collective_immunity),
        prevalence: Number(prevalence),
        ground_disrespect: Number(Ground_Disrespect),
        r0_latest: Number(r0_latest),
        r0_percent_change: Number(r0_percent_change),
      })
    )
  );

  const worldTraffic = (await importCsv("./data/Traffic_Data_World.csv")).map(
    ({ Origin_Country, Dest_Country, Traffic }: any) => ({
      origin_country: Origin_Country,
      dest_country: Dest_Country,
      traffic: Traffic,
    })
  );

  const testScenarios = (await importCsv("./data/Test_Scenarios.csv")).map(
    ({
      Index,
      Scenario_Name,
      Test_Eff_Departure,
      Test_Eff_Arrival,
      Apply_E2E_Infected,
      ...rest
    }: any) => ({
      ...rest,
      index: Index,
      name: Scenario_Name,
      test_eff_departure: Test_Eff_Departure || 0,
      test_eff_arrival: Test_Eff_Arrival || 0,
      apply_e2e_infected: Apply_E2E_Infected === "Y",
    })
  );
  return {
    statsPerCountry,
    worldTraffic,
    testScenarios,
  };
};
